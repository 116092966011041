@use "../constants";
@use "../mixins";

.sec-prod-lifecycle-section {
  padding: 75px 0 150px;
  background-color: white;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding-bottom: 75px;
  }

  .cyber-rating {
    width: 228px;
    object-fit: contain;
  }

  h3 {
    margin: 0;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

.sec-lifecycle-expertise-grid {
  display: grid;
  grid-template-columns: 228px 8rem 1fr;
  align-items: center;
  max-width: 1016px;
  margin: 0 auto;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
