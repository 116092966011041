@use "../constants" as constants;
@use "../mixins.scss" as mixins;
@use "./constants" as navConstants;

.subNavbar {
  height: 75px;
  border-bottom: 1px solid constants.$border-gray;

  @include mixins.respond-max(constants.$breakpoint) {
    height: navConstants.$navbar-height_mobile;
  }

  .subNavbar-content {
    position: relative;
    height: 100%;
    width: 90%;
    margin: auto;
  }

  .flex-1 {
    flex: 1;
  }

  .header-minWidth {
    min-width: 225px;
  }
}
