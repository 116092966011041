@use "../common/fonts/mixins" as fonts;
@use "../mixins.scss" as m;

$border-gray: #e1e1e1;
$v2-error-red: #ff5a46;
$input-padding: 8px;

.input-group {
  position: relative;
  text-align: left;

  * {
    @include fonts.acumin-pro;
  }

  .label {
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 10px;
    left: 0;
    transition: 200ms;
    font-size: 15px;
    font-weight: 500;
    color: #7f8c8d;
    padding-left: 0;
  }

  .focus {
    font-size: 12px !important;
    transform: translate3d(0, -100%, 0);
  }

  input,
  textarea,
  .dropdown {
    padding: 15px 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000;
    font-size: 18px;
    font-weight: 500;
    caret-color: #233752;
    -webkit-appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  input:focus + .label {
    outline: none !important;
    font-size: 12px;
    transform: translate3d(0, -100%, 0);
    color: #000000;
    font-weight: bold;
  }

  input.error {
    border-bottom: 1px solid red;
  }

  textarea {
    resize: none;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .error-message {
    color: red;
    position: absolute;
    font-size: 12px;
    left: 5px;
  }

  .bar:before,
  .bar:after {
    content: "";
    height: 3px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #000000;
    transition: 0.3s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  .bar.dropdown-bar {
    z-index: 10;
  }
  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  .dropdown:focus ~ .bar:before,
  .dropdown:focus ~ .bar:after {
    width: 50%;
  }

  /*-------------------*/
  .icon-clear,
  .icon-dropdown,
  .icon-locked,
  .icon-unlocked {
    position: absolute;
    right: 5px;
    cursor: pointer;
    z-index: 5;
    bottom: 22px;
  }

  .icon-clear {
    // background-image: url('../../images/search-icon-clear@2x.png');
    height: 14px;
    width: 14px;
    background-size: contain;
  }

  .icon-clear.hidden {
    visibility: hidden;
  }

  .icon-dropdown {
    z-index: 10;
    // background-image: url('../../images/icon-down-arrow-navy@2x.png');
  }

  .dropdown-label {
    position: relative;
    top: -6px;
    padding-left: 8px;
  }
}

.input-group.read-only {
  .icon-clear {
    visibility: hidden;
  }

  .bar {
    visibility: hidden;
  }
}

/* NEW BORDERED VERSION STYLE THAT IS BEING USED IN UPLOAD FARM */
.input-group.v2 {
  input,
  textarea {
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    background-color: #ffffff;
    padding: $input-padding 25px $input-padding $input-padding;
    transition: 0.3s ease border;
    font-weight: 500;
    font-size: 16px;
    color: #152935;
    box-sizing: border-box;
  }

  input:hover,
  textarea:hover {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
  }

  input:focus,
  textarea:focus {
    border: 1px solid #152935;
  }

  input::placeholder,
  textarea::placeholder {
    color: #d0d0d0;
  }

  .label {
    position: relative;
    top: 0;
    font-size: 14px;
    color: #787878;
    padding-left: $input-padding;
    margin-bottom: 5px;
    text-align: left;
  }

  .icon-clear,
  .icon-dropdown,
  .icon-locked,
  .icon-unlocked {
    z-index: 5;
    right: 10px;
    bottom: 13px;
  }

  .icon-password-show,
  .icon-password-hidden {
    cursor: pointer;
    right: 34px;
    bottom: 10px;
    position: absolute;
  }

  .bar {
    display: none;
  }

  .icon-closeWrapper {
    position: absolute;
    right: 5px;
    cursor: pointer;
    z-index: 5;
    top: calc(50% + #{$input-padding});
    transform: translateY(calc(-50% + #{$input-padding}));
    color: hsl(0, 0%, 80%);

    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }

  .icon-close {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
}

.input-group.v2.error {
  input,
  textarea {
    border: 1px solid $v2-error-red;
    color: $v2-error-red;
  }

  input::placeholder,
  textarea::placeholder {
    color: $v2-error-red;
  }

  .error-message-container {
    position: absolute;
    // top: 64px;
    text-align: left;
    padding: 0;
    width: 100%;
    z-index: 100;
  }

  .error-message-container.error-box-shadow {
    padding: 5px 0;
    background: white;
    border-radius: 2px;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  .error-message {
    color: $v2-error-red;
    position: relative;
    top: 0;
    left: $input-padding;
    font-size: 11px;
  }
}

.input-group.v2.read-only {
  input,
  textarea {
    background-color: #fafafa;
    pointer-events: none;
  }
}
