@use "../constants";
@use "./constants" as aboutConstants;
@use "../mixins";

.backedBy-section {
  padding: aboutConstants.$section-padding 0;
  @include aboutConstants.sectionPaddingMobile(constants.$breakpoint-sm);

  .section-content {
    max-width: 900px;
    position: relative;
  }

  .logo-margin {
    margin: 20px;
  }

  .flex {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      flex-direction: column;
    }
  }

  .dashedLineGroup-1 {
    bottom: -55px;
    width: 80%;
  }
}
