@use "../mixins";
@use "../constants";

$breakpoint-tablet: 1250px;

.home-hero-sections-container {
  // NOTE - having specific heights for this class  helps the other content on the page not jump as the hero slides change on timer
  background-color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 875px;
  margin-top: -100px;
  padding-top: 100px;

  @include mixins.respond-max($breakpoint-tablet) {
    height: 1140px;
  }

  @include mixins.respond-max(constants.$breakpoint-xsm) {
    height: 1350px;
  }

  .hero-section.homePage {
    padding-bottom: 0;
    flex: 1;
    background-color: black;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .floating-graphic-desktop {
    position: absolute;
    z-index: 2;
    width: 400px;
    object-fit: contain;
    top: 4rem;
    right: 7rem;
  }

  .floating-graphic-mobile {
    position: relative;
    z-index: 2;
    object-fit: contain;
    width: 100%;
    min-width: 280px;
    max-width: 400px;
    height: 100%;
    max-height: 500px;
    grid-area: 2/1/14/5;
  }

  .floating-photo-desktop {
    width: 625px;
    height: 675px;
    position: absolute;
    z-index: 0;
    object-fit: contain;
  }

  .floating-photo-mobile {
    z-index: 0;
    object-fit: contain;

    @include mixins.respond-max($breakpoint-tablet) {
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      top: 0;
      position: relative;
      grid-area: 1/3/15/9;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      grid-area: 1/1/15/9;
    }
  }

  .btns-section {
    position: relative;
    z-index: 10;
    margin: -150px auto 4rem auto;

    @include mixins.respond-max($breakpoint-tablet) {
      position: sticky;
      bottom: 0;
      background: black;
      width: 100%;
      margin: 0;
      box-shadow: 0px -1px 0px 0px rgb(255 255 255 / 40%);
    }
  }

  .btns-wrapper {
    display: flex;
    gap: 1rem;

    @include mixins.respond-max($breakpoint-tablet) {
      justify-content: center;
      padding: 1rem;
    }
  }

  .txt-subheader {
    color: #bebebe;
    font-size: 16px;
    padding-top: 50px;
    line-height: 19px;
    font-weight: 600;

    @include mixins.respond-max($breakpoint-tablet) {
      padding-top: 0;
    }
  }

  .content {
    position: relative;
    z-index: 10;
  }

  h1 {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 52px;
    max-width: 900px;

    @include mixins.respond-max($breakpoint-tablet) {
      font-size: 48px;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 36px;
      line-height: 48px;
    }
  }

  .txt-light {
    max-width: 80%;
    margin-bottom: 10px;

    @include mixins.respond-max($breakpoint-tablet) {
      max-width: none;
      font-size: 20px;
      line-height: 34px;
    }

    @include mixins.respond-max(700px) {
      max-width: none;
      margin-top: 0px;
    }
  }

  .btn-learn-more-hero {
    position: relative;
    z-index: 100;
    border: none;
    margin-top: 30px;
    width: max-content;
  }

  .dashedLineGroup-1 {
    top: 20px;

    @include mixins.respond-max($breakpoint-tablet) {
      display: none;
    }
  }

  .mobile-photo-grid {
    @include mixins.respond-max($breakpoint-tablet) {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(14, 1.75rem);
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }

  .desktop-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr) repeat(8, 4rem);
    grid-template-rows: repeat(16, 3rem);
    @include mixins.respond-max($breakpoint-tablet) {
      display: none;
    }
  }

  .desktop-text {
    grid-area: 1 / 1 / 12 / 9;
  }

  .desktop-photos {
    position: relative;
    grid-area: 6 / 10 / 17 / 17;
  }

  .mobile-stack {
    display: none;
    @include mixins.respond-max($breakpoint-tablet) {
      display: block;
    }
  }

  .max-content {
    width: max-content;
    display: block;
  }
}
