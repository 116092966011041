@use "../mixins";
@use "../constants";

$background-gray: #f4f4f4;

.pressReleaseRow {
  a {
    align-items: center;
    justify-content: flex-end;
  }

  .pressRelease-grid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 2em;
    border-bottom: 1px solid;
    padding: 25px 10px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      grid-template-columns: 1fr;
      grid-gap: 1em;
    }
  }

  .title {
    flex: 1;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      margin-top: -10px;
    }
  }

  .title-maxWidth {
    max-width: 325px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.pressReleaseRow:hover {
  background-color: #e9e9e9;
}

.latestNews-section.homePage {
  padding: 100px 0 200px;
  background-color: $background-gray;

  @include mixins.respond-max(constants.$breakpoint) {
    padding: constants.$section-mobilePadding-mdX2 0;
  }

  a {
    color: black;
  }

  .pressRelease-grid {
    border-bottom: 1px solid black;
  }

  .pressReleaseRow.linkRow {
    .pressRelease-grid {
      border-bottom: none;
    }

    .btn {
      min-width: 170px;
      float: right;
      position: relative;
      right: -15px;
    }
  }

  .pressReleaseRow.linkRow:hover {
    background-color: transparent;
  }
}
