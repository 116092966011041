@use "../mixins";
@use "../constants";

.hide-wrapper {
  position: relative;
  .inc5000-main-wrapper {
    transition: ease transform 1.5s;
    width: 100%;
    z-index: 99;
  }

  .inc5000-main-wrapper.show {
    position: relative;
    transform: translateY(0);
  }

  .inc5000-main-wrapper.hide {
    position: absolute;
    transform: translateY(-100vw);
  }
}

.inc-banner-section {
  background-color: #141414;
  //   background-image: url("../../images/graphics/graphic-textBackground.svg");
  div {
    color: white;
  }

  .button-close {
    background-image: url("../../images/icons/icon-close-white.png");
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    position: absolute;
    right: 15px;
    top: 15px;
    background-size: contain;
    z-index: 5;
  }

  .background-img-first {
    background-image: url("../../images/graphics/graphic-textBackground.svg");
    width: 112%;
    height: 550px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    left: -35px;
    right: 0;
    top: -15px;
    @include mixins.respond-max(1150px) {
    }
    @include mixins.respond-max(970px) {
    }
    @include mixins.respond-max(430px) {
      background-image: url("../../images/graphics/graphic-bg-mobile.svg");
      height: 280px;
      left: -5px;
      right: 0;
      top: -5px;
    }
  }

  .inc5000-logo-big {
    background-image: url("../../images/graphics/Inc-5000-Color-Medallion-Logo.png");
    height: 32vw;
    width: 32vw;
    background-size: contain;
    margin-top: 30px;
    max-width: 370px;
    max-height: 370px;
    @include mixins.respond-max(1050px) {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    @include mixins.respond-range(520px, 600px) {
      margin-bottom: 30px;
      margin-top: 10px;
      height: 30vw;
      width: 30vw;
    }
    @include mixins.respond-max(520px) {
      display: none;
    }
  }
  .inc5000-logo-mobile {
    background-image: url("../../images/graphics/Inc-5000-Color-Medallion-Logo.png");
    height: 110px;
    width: 110px;
    background-size: contain;
    margin-top: 20px;
    margin-right: 20px;
    background-repeat: no-repeat;
    @include mixins.respond-min(520px) {
      display: none;
    }
  }

  .inc-main-container {
    display: flex;
    position: relative;
  }

  .inc-rightside-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .inc-rightside-top-row-container {
      margin: 0 auto;
      @include mixins.respond-max(520px) {
        display: flex;
        flex-direction: row;
      }
      .inc-rightside-main-number {
        font-size: 180px;
        font-weight: 900;
        @include mixins.respond-max(1050px) {
          font-size: 130px;
        }
        @include mixins.respond-max(750px) {
          font-size: 90px;
        }
        @include mixins.respond-max(600px) {
          font-size: 70px;
        }
        @include mixins.respond-max(520px) {
          margin-top: 20px;
        }
      }
    }
    .inc-rightside-bottom-row-container {
      display: flex;
      justify-content: space-around;
      margin-bottom: 60px;
      @include mixins.respond-max(520px) {
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .inc-rightside-text-container {
        .inc-rightside-number {
          font-size: 72px;
          font-weight: 800;
          text-align: center;
          @include mixins.respond-max(1050px) {
            font-size: 50px;
          }
          @include mixins.respond-max(600px) {
            font-size: 30px;
          }
        }
        .inc-rightside-text {
          font-size: 28px;
          text-align: center;
          @include mixins.respond-max(1050px) {
            font-size: 20px;
          }
          @include mixins.respond-max(600px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
