@use "../mixins";
@use "../constants";

.icon-close-black {
  background-image: url("../../images/icons/icon-close-black@2x.png");
  @include mixins.imgCenterContain;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.graphic-order-progress {
  background-image: url("../../images/home/order-progress-graphic@2x.jpg");
}

.dot-pattern {
  background-image: url("../../images/home/dot-pattern-lg@2x.png");
}

.dot-pattern-wide {
  background-image: url("../../images/home/dot-pattern-wide@2x.png");
}

.icon-check_white {
  background-image: url("../../images/icons/icon-checkmark.white@2x.png");
  @include mixins.imgCenterContain;
  width: 12px;
  height: 12px;
}

.icon-check_large {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-arrow_right {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-arrow_back {
  cursor: pointer;
  padding: 10px;
}

.icon-costs {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-star {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-lock {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-architecture {
  background-image: url("../../images/icons/icon-valuation.equity@2x.png");
  @include mixins.imgCenterContain;
}

.icon-linkedIn {
  background-image: url("../../images/icons/icon-linkedin-profile@2x.png");
  @include mixins.imgCenterContain;
  width: 25px;
  height: 25px;
}

.icon-linkedIn_disabled {
  opacity: 0.25;
}

.icon-rocket {
  background-image: url("../../images/icons/icon-rocket@2x.png");
  @include mixins.imgCenterContain;
  width: 40px;
  height: 40px;
}

.gridItem-icon {
  width: 50px;
  height: 50px;

  @include mixins.respond-max(constants.$breakpoint-xsm) {
    margin: auto;
  }
}
