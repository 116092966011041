@use "../mixins";
@use "../constants";
@use "./constants" as aboutConstants;

$section-content_padding: 30%;

.coreValues-section {
  position: relative;
  padding: aboutConstants.$section-padding 0 aboutConstants.$section-padding_large;
  @include aboutConstants.sectionPaddingMobile(constants.$breakpoint-sm);

  .section-content:first-child {
    padding-right: $section-content_padding;
  }

  .section-content:last-child {
    padding-left: $section-content_padding;
  }

  .section-content:first-child,
  .section-content:last-child {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-content {
    max-width: 440px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      max-width: none;
    }
  }

  .img-numbers {
    background-image: url("../../images/graphics/graphic-numbers.jpg");
    background-position: 17%;
    background-size: cover;
    position: absolute;
    bottom: 555px;
    z-index: -1;
    width: 102vw;
    height: 1100px;

    // get jhonnie's approval on this:
    background-repeat: no-repeat;
    height: 500px;
    width: 1708px;
    background-position: center;
    margin: auto;
    position: relative;
    bottom: 156px;
    max-width: 1708px;
    width: 100vw;

    @include mixins.respond-max(constants.$breakpoint) {
      // height: 600px;
      // bottom: 700px;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      position: relative;
      bottom: 0;
      margin-top: -150px;
      height: 400px;
    }
  }

  .section-content.numbers-marginTop {
    // margin-top: 335px;

    margin-top: -145px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: 0;
    }
  }

  .border-fade_mobileNone {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      box-shadow: none;
    }
  }

  h2:nth-of-type(2) {
    margin-top: 100px;
  }

  p {
    max-width: 975px;
    line-height: 30px;
    margin-top: 0;
  }

  .p-noMargin {
    margin: 0;
  }

  .p-2 {
    font-size: 16px;
    line-height: 28px;
  }

  .dashedLineGroup-1 {
    margin-top: -100px;
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: -70px;
    }
  }
}
