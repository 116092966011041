@use "./flueidDifferentSection.scss";
@use "./heroSectionSecurity.scss";
@use "./productDevelopmentSection.scss";
@use "./productLifecycleSection.scss";
@use "./quotesSection.scss";
@use "./trustExpertiseSection.scss";
@use "./trustPlatformSection.scss";

@use "../constants";

.sec-parallax-section {
  position: sticky;
  top: 0;
  z-index: -1;
}

.sec-bg-green {
  background-color: constants.$security-green;
}

.sec-bg-green-dark {
  background-color: constants.$security-green-dark;
}
