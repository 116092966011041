@use "../mixins";
@use "../constants";

.features-section {
  padding: 100px 0;
  position: relative;

  @include mixins.respond-max(constants.$breakpoint) {
    padding: constants.$section-mobilePadding-md 0;
  }

  .bg-quote-blue {
    color: #ddeafa;
  }

  .featureGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 30px;
    margin-top: 80px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      grid-template-columns: 1fr;
      text-align: center;
      grid-gap: 50px;
    }
  }

  .featureGrid-item {
    max-width: 450px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      text-align: left;
    }

    @include mixins.respond-min(constants.$breakpoint) {
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  h6 {
    margin: 10px 0;
    font-size: 24px;
  }

  span h4 {
    display: inline;
  }

  .m-topBot-75 {
    margin: auto;
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .txt-extraLight.m-topBot-75 {
    text-align: center;
  }

  .txt-extraLight {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
  }

  .btn {
    margin: auto;

    @include mixins.respond-max(550px) {
      margin: 0;
    }
  }

  .background-light-blue {
    background-color: #f1f8ff;
    padding: 150px 0px;
  }

  .quote-section-container {
    display: flex;
    z-index: 10;
    position: relative;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }
  }

  .matt-regan-story {
    height: 500px;
    object-fit: contain;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 150px;
      height: 150px;
      border-radius: 150px;
      background-size: cover;
      background-position: top left 0px;
      margin: 0 auto;
    }
  }

  .right-text-container-quote {
    background-image: url("../../images/icons/icon-quote.svg");
    @include mixins.imgCenterContain;
    background-size: 20%;
    background-position: top left;
    padding-left: 60px;

    @include mixins.respond-max(constants.$breakpoint) {
      padding-left: 0px;
    }

    p {
      line-height: 30px;
    }
  }

  .arrow-learn-more {
    background-image: url("../../images/icons/arrow-right-blue.svg");
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    margin-top: 5px;
    margin-left: 15px;
  }

  .txt-underline {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    @include mixins.textLink;
  }
  .arrow-flex-container {
    display: flex;
  }

  .margin-top-quote {
    font-size: 28px;
  }

  .txt-quote-main {
    margin-bottom: 0px;
    margin-top: 80px;
    font-size: 29px;
    font-weight: 600;
  }

  .margin-top-quote,
  .txt-quote-main {
    @include mixins.respond-max(constants.$breakpoint) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .txt-align-center {
    text-align: center;
  }

  .fuel-top-header {
    margin-top: 150px;
    margin-bottom: 0px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      text-align: left;
    }
  }

  .p-text-fuel {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      text-align: left;
    }
  }

  .width-banner-fix-desktop {
    display: flex;
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
    padding-bottom: 200px;

    @include mixins.respond-max(1310px) {
      display: none;
    }

    .demo-box-container {
      display: flex;
      justify-content: center;
      right: 10%;
      margin-top: -80px;
      position: absolute;
      width: 450px;
      margin-right: 0%;

      @include mixins.respond-max(constants.$breakpoint) {
        margin-top: 50px;
        margin-right: 0px;
      }

      @include mixins.respond-max(1500px) {
        right: 7%;
      }

      @include mixins.respond-max(1400px) {
        right: 10%;
      }

      @include mixins.respond-max(1310px) {
        width: 450px;
        position: relative;
        margin: 0 auto;
      }

      .demo-box-content {
        width: 100%;
      }
    }
  }

  .width-banner-fix-mobile {
    @include mixins.respond-min(1310px) {
      display: none;
    }
  }

  .demo-box-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 11%;
    margin-top: -80px;

    @include mixins.respond-max(constants.$breakpoint) {
      margin-top: 50px;
      margin-right: 0px;
    }
  }

  .dashedLineGroupInterested {
    margin-top: -120px;

    @include mixins.respond-max(800px) {
      display: none;
    }
  }

  .demo-box-content {
    width: 30%;
    background: linear-gradient(180deg, rgb(239, 242, 246) 0%, rgba(252, 253, 255, 0) 100%);
    padding: 55px;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 40%;
      margin: 0 auto;
    }

    @include mixins.respond-max(600px) {
      width: 90%;
    }

    h3 {
      margin: 0px;
      font-size: 24px;
    }

    p {
      margin-top: 0px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      padding: 15px 0px 30px 0px;
    }

    .btn-left-align {
      margin: 0;
    }
  }
}
