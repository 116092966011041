@use "../mixins";
@use "../constants";

.report-container {
  background-color: black;
  padding-top: 80px;
  padding-bottom: 80px;
  .report-content {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
    @include mixins.respond-max(1300px) {
      flex-direction: column;
    }
  }
  .report-block {
    max-width: 375px;

    padding-top: 20px;
    padding-right: 30px;
    padding-left: 30px;
    color: #616366;

    @include mixins.respond-max(1300px) {
      margin: 0 auto;
      margin-bottom: 50px;
      padding-right: 0px;
    }
    @include mixins.respond-max(400px) {
      width: 90%;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  .report-bar {
    width: 375px;
    height: 5px;
    background-color: #616366;
    margin-bottom: 30px;
    @include mixins.respond-max(400px) {
      width: 100%;
    }
  }
  .report-transition-bar1 {
    transition: background-color 1s ease-in 1s;
  }
  .report-transition-bar2 {
    transition: background-color 1s ease-in 2s;
  }
  .report-transition-bar3 {
    transition: background-color 1s ease-in 4s;
  }

  .report-transition0 {
    transition: color 1s ease-in 1s;
  }
  .report-transition {
    transition: color 1s ease-in 2s;
  }
  .report-transition2 {
    transition: color 1s ease-in 4s;
  }
  .report-block-header {
    font-weight: 600;
    font-size: 20px;
  }

  .report-block-text {
    line-height: 24px;
    padding-top: 10px;
  }
  .white {
    color: white;
  }
  .white-background {
    background-color: white;
  }
  .report-title {
    font-size: 36px;
    font-family: "abril fatface";
    color: white;

    width: 45%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
    @include mixins.respond-max(900px) {
      font-size: 32px;
      width: 90%;
    }
  }
  .report-bottom-text {
    color: white;
    font-size: 20px;
    width: 40%;
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
    @include mixins.respond-max(900px) {
      width: 90%;
    }
  }
}
