@use "./teamMemberCard.scss";
@use "./teamSection.scss";
@use "./timelineSection.scss";
@use "./coreValuesSection.scss";
@use "./testimonySection.scss";
@use "./backedBySection.scss";
@use "../mixins";
@use "../constants";

.aboutPage-container {
  background: linear-gradient(to bottom, #3c3c3c 2%, transparent 2%);
}

.aboutPage-heroSection {
  background-image: url("../../images/graphics/aboutPageHeroBanner.jfif");
  background-size: cover;
  height: 715px;
  position: relative;
  background-position: 30%;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    height: auto;
  }

  h1 {
    margin: 10px 0;
  }

  h3 {
    margin: 0;
    line-height: 51px;
  }

  .h3-large {
    font-size: 38px;
    max-width: 650px;
  }

  .subheader-2 {
    color: #747474;
  }

  .dashedLineGroup-1 {
    width: 35%;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .dashedLineGroup-2 {
    bottom: 24%;
    width: 40%;

    @include mixins.respond-range(constants.$breakpoint-sm, constants.$breakpoint-md) {
      display: none;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: 12%;
      width: 75%;
    }
  }
}

.mission-block {
  position: absolute;
  bottom: -110px;
  right: 0;
  width: 525px;
  background: url("../../images/graphics/graphic-dots.square.png");
  background-size: cover;

  h3 {
    color: black;
    background: white;
    margin: 60px;
    padding: 30px;
    position: relative;
  }
}

.mission-block_desktop {
  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: none;
  }
}

.mission-block_mobile {
  display: none;
  width: 100%;
  position: relative;
  bottom: 0;
  background-size: 125%;

  h3 {
    margin: 5%;
    padding: 5%;
  }
  @include mixins.respond-max(constants.$breakpoint-sm) {
    display: flex;
  }
}
