.text_link {
  display: flex;
  align-items: center;
  gap: 1rem;
  max-width: max-content;
  transition: 0.3s ease-in-out opacity;

  &:hover {
    opacity: 0.75;
  }
}
