@use "../mixins";
@use "../constants";

.services-container {
  padding-bottom: 80px;
  .services-flex-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    gap: 50px;

    max-width: 1400px;
    margin: 0 auto;
  }
  .services-flex-block {
    max-width: 375px;
    margin: 0 auto;

    @include mixins.respond-max(700px) {
      width: 90%;
    }
  }
  .services-block-title {
    color: #1e63c9;
    font-size: 20px;
    font-weight: 700;
  }
  .services-title {
    font-size: 36px;
    margin: 80px auto;
    text-align: center;
    font-family: "abril fatface";
  }
  .text-blue {
    color: #1e63c9;
  }
  .services-block-text {
    margin-top: 10px;
    line-height: 24px;
  }
}
