//************************
// NEW CAREERS PAGE STYLES
//************************
@use "../constants";
@use "../mixins";
@use "../common/fonts/mixins" as fonts;

$testimony-breakpoint: 850px;
$standardsGrid-breakpoint: 525px;

.careersPage-container {
  section:not(.hero-section) {
    padding: 100px 0;
  }

  .section-txt_maxWidth {
    max-width: 420px;
  }
}

.careersPage-heroSection.hero-section {
  background-color: #f6f6f6;
  padding-bottom: 70px;

  .content {
    max-width: 1400px;
  }

  .at-the-core-text {
    background-image: url("../../images/graphics/ATClogo.svg");
    width: 180%;
    height: 210%;
    background-repeat: no-repeat;
    left: -20%;
    top: -15%;
    position: absolute;
    z-index: 2;
    opacity: 0.4;
    background-attachment: fixed;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      background-position: 0% 5%;
      top: -40%;
    }

    @include mixins.respond-min(1600px) {
      left: -40%;
    }
  }

  .content {
    position: relative;
  }

  h1 {
    margin: 10px 0;
  }

  .text-red {
    color: #ea5353;
  }

  h5.txt-light {
    max-width: 666px;
    margin-right: 455px;

    @include mixins.respond-max(900px) {
      max-width: none;
      margin-right: 0;
    }
  }

  .subheader-2 {
    color: #747474;
  }

  .dashedLineGroup-1 {
    margin-top: 200px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin-top: 100px;
      width: 75%;
    }
  }

  .dashedLineGroup-1-small-margin {
    margin-top: 100px !important;

    @include mixins.respond-max(constants.$breakpoint-md) {
      width: 75%;
    }
  }

  .z-index-4 {
    position: relative;
    z-index: 4;
  }

  .circle-background {
    width: 680px;
    height: 680px;
    border-radius: 50%;
    position: absolute;
  }
}

.discover-section {
  padding-bottom: 50px !important;
  background-color: #f6f6f6;
  color: #ea5353;
  padding-top: 0px !important;

  h2 {
    margin-bottom: 125px;
    max-width: 400px;
  }

  h3 {
    font-weight: 600;
  }

  h3:first-child {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }

  .p-width_sm {
    max-width: 525px;
    color: black;
    font-weight: 400;
    padding-bottom: 50px;
  }

  .discover-circle {
    height: 710px;
    width: 709px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .discover-header-flex {
    display: flex;

    @include mixins.respond-max(1100px) {
      flex-direction: column;
    }
  }

  .masonry-hero-careers {
    width: 700px;
    margin-top: 50px;

    @include mixins.respond-max(1200px) {
      width: 600px;
    }

    @include mixins.respond-max(1100px) {
      margin: 0 auto;
      margin-top: 50px;
      width: 700px;
    }

    @include mixins.respond-max(800px) {
      width: auto;
    }

    .masonry-column {
      .masonry-top-row {
        position: relative;
        z-index: 2;
        margin-left: 10px;
        margin-right: 10px;
        flex-direction: column;

        .img-careers-middle1 {
          border-radius: 5px;
          background-image: url("../../images/people/careershero2.png");
          width: 100%;
          height: 250px;
          margin-bottom: 20px;
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: 0% 20%;

          @include mixins.respond-max(700px) {
            display: none;
          }
        }
      }
    }

    .masonry-rows {
      display: flex;
      flex-direction: row;
    }

    .masonry-row-container {
      height: 100%;
      width: 40%;
      margin: 0px 10px;

      .img-careers-masonry {
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        position: relative;
        z-index: 2;
      }

      .img-careers-left {
        background-image: url("../../images/people/Crystal_CareersPage.jpg");
        width: 100%;
        height: 430px;
        background-position: 30%;

        @include mixins.respond-max(500px) {
          background-position: 50%;
        }
      }

      .img-careers-middle2 {
        background-image: url("../../images/people/careersbig.png");
        width: 100%;
        height: 370px;

        @include mixins.respond-max(500px) {
          background-position: 50%;
        }
      }

      .img-careers-right1 {
        background-image: url("../../images/people/dennis-frandsen.jpg");
        width: 100%;
        height: 260px;
        background-position: 50%;
        margin-top: 20px;

        @include mixins.respond-max(715px) {
        }

        @include mixins.respond-max(450px) {
        }
      }

      .img-careers-right2 {
        background-image: url("../../images/people/jason-careers.png");
        width: 100%;
        height: 320px;
        margin-bottom: 20px;
        background-position: 50% 40%;

        @include mixins.respond-max(500px) {
          background-position: 50%;
        }
      }
    }

    .left-masonry-row {
      width: 60%;
    }
  }

  .at-the-core-horizontal {
    background-image: url("../../images/graphics/ATC\ Horz.svg");
    width: 130%;
    height: 168px;
    background-repeat: no-repeat;
    margin-top: 80px;
    margin-bottom: 80px;
    position: relative;
    left: -7%;

    @include mixins.respond-max(1300px) {
      left: -14%;
    }

    @include mixins.respond-max(constants.$breakpoint-md) {
      display: none;
    }
  }

  .padding-top-discover {
    padding-top: 50px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin: 0 auto;
    }
  }

  .discover-bold-header {
    font-size: 34px;
    font-family: sans-serif;
    font-weight: 300;
    font-family: "acumin-pro";
    line-height: 48px;
    padding-bottom: 15px;
  }

  .core-text-right {
    padding-top: 30%;
    padding-left: 5%;

    @include mixins.respond-max(1100px) {
      padding-top: 50px;
      padding-left: 0%;
    }
  }

  .discover-bold-header-main {
    font-size: 48px !important;
    padding-bottom: 40px;
    font-family: "acumin-pro";
    line-height: 1em;
    padding-top: 20px;
    font-weight: 800;

    @include mixins.respond-max(1100px) {
      padding-top: 0px;
    }
  }

  .discover-bold-header {
    @include mixins.respond-max(constants.$breakpoint-xsm) {
      font-size: 25px;
    }
  }

  .intentional-section-group-margin {
    margin-bottom: 100px;

    @include mixins.respond-max(992px) {
      margin-bottom: 0px;
    }
  }

  .discover-bold-header-small {
    font-size: 34px;
    font-family: "acumin-pro";
    font-weight: 800;
    padding-top: 50px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      font-size: 25px;
      padding-top: 40px;
    }
  }

  .para-padding-bottom {
    font-weight: 300;
    line-height: 30px;
    font-size: 18px;
    color: black;

    @include mixins.respond-max(1000px) {
      width: 100%;
    }
  }

  .dashedline-spacing {
    padding: 100px 0px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      padding: 50px 0px;
    }
  }

  .flex {
    justify-content: space-between;

    @include mixins.respond-max(constants.$breakpoint-md) {
      flex-direction: column;
    }
  }

  .flex-mobile-reverse {
    @include mixins.respond-max(constants.$breakpoint-md) {
      flex-direction: column-reverse;
    }
  }

  .flex-colReverse_mobile {
    @include mixins.respond-max(constants.$breakpoint-md) {
      flex-direction: column-reverse;
    }
  }

  .img-right-container {
    width: 500px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin: 0 auto;
    }

    @include mixins.respond-max(600px) {
      width: 100%;
      margin-bottom: 50px;
    }

    .discover-img-right {
      background-image: url("../../images/graphics/Careers_DSC01868.jpg");
      background-size: cover;
      width: 100%;
      height: 559px;
      border-radius: 5px;
      background-repeat: no-repeat;
      position: relative;
      background-position: 50%;

      @include mixins.respond-max(600px) {
        background-size: cover;
        height: 89vw;
      }

      @include mixins.respond-max(constants.$breakpoint-md) {
        margin-bottom: 20px;
      }
    }
  }

  .discover-body-text-mobile {
    @include mixins.respond-max(constants.$breakpoint-md) {
      margin: 0 auto;
    }
  }

  .mobile-top-margin {
    @include mixins.respond-max(constants.$breakpoint-md) {
      margin-top: 70px;
    }
  }

  .img-left-container {
    width: 550px;
    margin-right: 30px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin: 0 auto;
    }

    @include mixins.respond-max(600px) {
      width: 100%;
    }

    .discover-img-left {
      background-image: url("../../images/people/DSC03486-Edit-3-min.jpg");
      background-size: cover;
      width: 100%;
      height: 550px;
      border-radius: 5px;
      background-repeat: no-repeat;
      position: relative;
      background-position: 30%;
      margin-bottom: 70px;

      @include mixins.respond-max(1110px) {
        background-position: 40%;
      }

      @include mixins.respond-max(600px) {
        background-position: 10%;
        background-size: contain;
        height: 73vw;
      }
    }
  }

  .photo-careers_collaboration {
    background-image: url("../../images/photos/photo-coreValues@2x.jpg");
    @include mixins.imgCenterContain;
    margin-left: 8%;
    width: 530px;
    height: 650px;
    background-size: cover;
    flex-shrink: 0;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin-left: 0%;
      width: 100%;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      height: 90vw;
    }
  }

  .photo-careers_intention {
    background-image: url("../../images/photos/photo-coreValues2@2x.png");
    @include mixins.imgCenterContain;
    margin-right: 8%;
    width: 530px;
    height: 550px;
    background-size: cover;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin-top: 50px !important;
      margin-right: 0%;
    }

    @include mixins.respond-max(500px) {
      width: 300px;
    }
  }

  .photo-careers_collaboration,
  .photo-careers_intention {
    background-position: top;
    background-repeat: no-repeat;

    @include mixins.respond-max(constants.$breakpoint-md) {
      margin: auto;
      position: relative;
      height: 65vw;
      background-position: center;
      background-size: contain;
    }

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      height: 90vw;
    }
  }

  .photo-careers_collaboration {
    @include mixins.respond-max(constants.$breakpoint-md) {
    }
  }

  .dashedLineGroup-1 {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: 0px;
      width: 75%;
    }
  }

  .dashedLineGroup-2 {
    margin-top: 100px;
    width: 75%;
  }
}

.offices-section {
  .section-content.flex {
    align-items: flex-end;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
      align-items: center;
    }
  }

  h2 {
    align-self: flex-start;
  }

  .officePhoto-block {
    text-align: center;
  }

  .officePhoto-margin {
    margin: 0 30px 0 60px;

    @include mixins.respond-max(constants.$breakpoint) {
      margin: 60px 0;
    }
  }

  .graphic-officeDots_austin,
  .graphic-officeDots_santaBarbara {
    width: 100%;
    height: 100%;
    position: relative;
    top: -20px;
    z-index: -1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
  }

  .graphic-officeDots_austin {
    background-image: url("../../images/graphics/graphic-office.dots.austin.png");
  }

  .graphic-officeDots_santaBarbara {
    background-image: url("../../images/graphics/graphic-office.dots.santabarbara@2x.png");
    right: -20px;
  }

  .officePhoto_austin,
  .officePhoto_santaBarbara {
    width: 275px;
    height: 275px;
  }

  .officePhoto_austin {
    background-image: url("../../images/photos/photo-office.austin@2x.jpg");
    background-size: cover;
  }

  .officePhoto_santaBarbara {
    background-image: url("../../images/photos/photosantabarbara@2x.jpg");
    background-size: cover;
  }
}

.offices-sectionv2-container {
  padding: 0px !important;
  position: relative;

  &:hover .center-block .center-block__content.default {
    color: white;
  }

  .austin-office-background {
    background-image: url("../../images/photos/photo-officeATX.jpg");
    background-position: bottom !important;

    &:hover ~ .center-block .center-block__content.austin {
      opacity: 1;
    }
  }

  .santa-office-background {
    background-image: url("../../images/photos/photo-sbOffice@2x.jpg");

    &:hover ~ .center-block .center-block__content.sb {
      opacity: 1;
    }
  }

  .center-block {
    background-color: white;
    box-sizing: border-box;
    height: 390px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 210px;
    width: 390px;

    @include mixins.respond-max(993px) {
      top: 277px;
    }
  }

  .center-block__bg {
    background: url('data:image/svg+xml,  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" ><rect x="0" width="10" height="10" /><rect x="20" y="20" width="10" height="10" /></svg>');
    background-size: 8px 8px;
    width: 125%;
    height: 125%;
    position: absolute;
    pointer-events: none;
    top: -12%;
    right: -12%;
    z-index: 10;

    @include mixins.respond-min(constants.$breakpoint) {
      width: 150%;
      height: 150%;
      top: -25%;
      right: -25%;
    }
  }

  .center-block__content {
    background-color: white;
    height: 100%;
    position: absolute;
    transition: opacity 0.4s;
    z-index: 20;
    align-items: center;
    display: flex;

    &.default {
      padding: 0 50px;
    }

    &.austin {
      padding: 0 40px 0;
      opacity: 0;
    }

    &.sb {
      padding: 0 40px 0;
      opacity: 0;
    }
  }

  .center-block__default-header {
    font-size: 40px;
    line-height: 1.2;
  }

  .center-block__header {
    font-size: 30px;
    line-height: 1.2;
  }

  .center-block__description {
    font-size: 20px;
    line-height: 32px;
    margin-top: 50px;
  }
}

.offices-sectionv2 {
  height: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;

  @include mixins.respond-max(993px) {
    height: 950px;
  }

  .dashedLineGroup-offices {
    width: 100%;
  }

  .office-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: grayscale(0.7);
    height: 100%;
    transition: filter 1.2s;
    width: 100%;

    &:hover {
      filter: grayscale(0);
    }

    @include mixins.respond-min(constants.$breakpoint) {
      height: 750px;
    }
  }

  @include mixins.respond-min(constants.$breakpoint) {
    flex-direction: row;
    height: 750px;
  }
}

.showcasePosition-section {
  background-color: black;
  color: white;
  position: relative;
  padding: 200px 0px !important;

  @include mixins.respond-max(630px) {
    padding-top: 450px !important;
  }

  h2 {
    margin-top: 0;
  }

  p {
    max-width: 950px;
  }

  .btn-white {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .flex-col_mobile {
    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column-reverse;
    }
  }

  .icon-arrow_white {
    margin-left: 10px;
  }

  .testimony-white-dots-bottom {
    background-image: url("../../images/graphics/graphic-dots-white-right@2x.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    height: 200px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 621px;
    width: 627px;
    z-index: 1;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .photo-careers_softwarePosition {
    width: 400px;
    height: 520px;
    flex-shrink: 0;
    background-image: url("../../images/photos/photo-featured@2x.png");
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 2;
    margin-left: 50px;

    @include mixins.respond-max(constants.$breakpoint) {
      display: none;
    }
  }

  .photo-careers_softwarePosition-mobile {
    width: auto;
    height: 400px;

    background-image: url("../../images/photos/photo-featured-horizontal@2x.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include mixins.respond-min(constants.$breakpoint) {
      display: none;
    }

    @include mixins.respond-max(571px) {
      background-image: url("../../images/photos/photo-featured@2x.png");
      margin-bottom: 100px;
    }
  }
}

.testimony-section-columns .slick-slide {
  align-self: flex-end;
}

.testimony-section-columns .slick-track {
  display: flex;
}

.testimony-section-columns {
  background-color: #ea5353;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  @include mixins.respond-range(constants.$breakpoint-sm, constants.$breakpoint-sml) {
    background-image: url("../../images/graphics/EMPLOYEESTORIES.svg");
    background-repeat: no-repeat;
    background-size: 106%;
    background-position: 50% 0%;
  }

  @include mixins.respond-max(1200px) {
    background-image: url("../../images/graphics/Employee_Stories_Mobile.svg");
    background-repeat: no-repeat;
    background-size: 95%;
    background-position: 50% -7%;
  }

  @include mixins.respond-max(1000px) {
    background-size: 100%;
    background-position: 50% -5%;
  }

  @include mixins.respond-max(700px) {
    background-size: 100%;
    background-position: 0% -2%;
  }

  .testimony-section-columns-inside-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1700px;

    .testimony-left-main-container {
      background-image: url("../../images/graphics/graphic-employess-stories@2x.png");
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      height: 630px;
      width: 38%;
      background-position: 30%;
      display: block;

      @include mixins.respond-max(1380px) {
        height: 58vw;
      }

      @include mixins.respond-max(1175px) {
        height: 64vw;
      }

      @include mixins.respond-max(1100px) {
        height: 74vw;
      }

      @include mixins.respond-max(constants.$breakpoint-sml) {
        display: none;
      }
    }

    .testimony-right-main-container {
      width: 40%;
      margin-right: 11%;

      @include mixins.respond-max(constants.$breakpoint-sml) {
        width: 70%;
        margin: 0 auto;
        padding-bottom: 70px;
      }

      @include mixins.respond-max(constants.$breakpoint-sm) {
        width: 100%;
      }
    }

    .employee-stories-img-text {
      padding-top: 1px !important;
      position: relative;
      z-index: 2;
      display: none;
    }

    .text-container-desktop {
      position: relative;
      z-index: 1;

      @include mixins.respond-max(600px) {
      }
    }

    .section-content {
      padding-top: 200px;

      @include mixins.respond-max(1600px) {
        padding-top: 150px;
      }

      @include mixins.respond-max(1100px) {
        padding-top: 250px;
      }

      @include mixins.respond-max(constants.$breakpoint-sm) {
        padding-top: 200px;
      }

      @include mixins.respond-max(600px) {
        padding-top: 50px;
      }

      @include mixins.respond-max(450px) {
        padding-top: 0px;
      }
    }

    .visibility-toggle-600 {
      @include mixins.respond-max(600px) {
        display: none;
      }
    }

    .testimony-section-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: masonry;

      @include mixins.respond-max(1150px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mixins.respond-max(constants.$breakpoint-sm) {
        display: none;
      }
    }

    .flex {
      flex-direction: column;
      justify-content: center;
    }

    .flex-btn-testimony {
      max-width: 1400px;
      margin: 0 auto;
      margin-top: 80px;

      @include mixins.respond-range(constants.$breakpoint-sm, constants.$breakpoint-sml) {
        margin-top: 220px;
      }

      @include mixins.respond-max(constants.$breakpoint-sm) {
        margin-top: 0px;
      }
    }

    .arrow-circle-container {
      border: 2px solid #ea5353;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;

      @include mixins.respond-max(768px) {
        display: none;
      }
    }

    .arrow-circle-container:hover {
      .arrow-right-img {
        transform: translate3d(10px, 0px, 0px);
      }

      .arrow-left-img {
        transform: scaleX(-1) translate3d(10px, 0px, 0px);
      }
    }

    .arrow-testimony {
      background-image: url("../../images/icons/Arrow.svg");
      background-repeat: no-repeat;
      width: 30%;
      height: 100%;
      top: 25%;
      left: 50%;
      position: absolute;
      transition: transform 0.2s ease-in-out;

      @include mixins.respond-max(600px) {
        width: 50%;
        height: 80%;
        left: 40%;
        top: 15%;
      }
    }

    .arrow-left-img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      left: 20%;

      @include mixins.respond-max(600px) {
        left: 10%;
        top: 15%;
      }
    }

    .arrow-left {
      left: 42%;
      top: 45%;

      @include mixins.respond-max(constants.$breakpoint-sml) {
        left: 5%;
        top: 50%;
      }

      @include mixins.respond-max(600px) {
        top: 80%;
      }
    }

    .arrow-right {
      right: 5%;
      top: 45%;

      @include mixins.respond-max(constants.$breakpoint-sml) {
        right: 5%;
        top: 50%;
      }

      @include mixins.respond-max(600px) {
        top: 80%;
      }
    }

    .discover-bold-header-main {
      font-size: 64px;
      padding-bottom: 20px;
      padding-top: 120px;
      font-family: "acumin-pro";
      font-weight: 800;
      color: white;
      margin: 0 auto;
      text-align: left;
      line-height: 1em;

      @include mixins.respond-max(constants.$breakpoint-sm) {
        margin-top: 50px;
      }

      @include mixins.respond-max(constants.$breakpoint-xsm) {
        font-size: 58px;
      }
    }

    .testimony-mobile-card-bold {
      font-weight: bold;
      width: 90%;
      margin-top: 40px;
      text-align: left;

      @include mixins.respond-max(600px) {
        text-align: center;
      }
    }

    .testimony-mobile-card-subtitle {
      color: white;
      font-size: 20px;
      line-height: 32px;
      width: 75%;
      text-align: left;
      margin: 0 auto;

      @include mixins.respond-min(constants.$breakpoint-sml) {
        width: 100%;
      }

      @include mixins.respond-max(640px) {
        width: 100%;
      }

      @include mixins.respond-max(600px) {
        margin: 0 auto;
        padding-top: 10px;
        line-height: 22px;
        text-align: left;
      }
    }

    .subtitle-small {
      font-size: 16px;

      @include mixins.respond-max(600px) {
        height: 70px;
      }
    }

    .testimony-card-careers-mobile {
      padding-left: 5%;
      padding-right: 5%;

      @include mixins.respond-max(constants.$breakpoint-sm) {
        padding-top: 120px;
      }

      @include mixins.respond-max(600px) {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }

    .btn-arrow {
      background-color: white !important;
    }

    .btn-arrow_next {
      color: #888888 !important;
    }

    .btn-arrow_prev {
      color: #888888 !important;
    }

    .testimony-quote-mobile {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
    }

    .testimony-white-dots-bottom {
      background-image: url("../../images/graphics/graphic-dots-white@2x.png");
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
      height: 200px;
      position: absolute;
      bottom: 0px;
      height: 850px;
      width: 850px;
      z-index: 1;

      @include mixins.respond-max(constants.$breakpoint-sm) {
        display: none;
      }
    }

    .testimony-main-column {
      display: flex;
      flex-direction: column;
      z-index: 2;
    }

    .testimony-column-block {
      background-color: white;
      padding: 40px;
      margin: 30px;
    }

    .testimony-column-block-desktop {
      @include mixins.respond-max(1150px) {
        display: none;
      }
    }

    .testimonial-header-light {
      color: white;
      margin: 0 auto;
      font-size: 32px;

      text-align: left;
      margin-bottom: 120px;

      @include mixins.respond-max(constants.$breakpoint-sm) {
        margin-bottom: 80px;
      }
    }

    .testimony-block-text {
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      font-style: normal;
      text-align: left;
      text-indent: -9px;
      margin: auto;
      top: 18%;
      position: relative;
      height: 170px;

      @include mixins.respond-max(constants.$breakpoint-sml) {
        font-size: 20px;
        line-height: 28px;
        width: 75%;
        height: 200px;
      }

      @include mixins.respond-max(constants.$breakpoint-sm) {
        text-align: left;
        padding-bottom: 30px;
      }

      @include mixins.respond-range(760px, 900px) {
        height: 250px;
      }

      @include mixins.respond-range(640px, 760px) {
        height: 200px;
      }

      @include mixins.respond-max(640px) {
        width: 100%;
        font-size: 16px;
        height: 200px;
      }
    }

    .testimony-photo-desktop {
      display: relative;
    }

    .testimony-photo {
      margin-right: 0px;
      position: relative;
      z-index: 1;
    }

    .testimony-block-text-red {
      color: #ff546e;
    }

    .bottom-half-testimonial {
      display: flex;
      align-items: center;
    }

    .text-bold {
      font-weight: bold;
    }

    .text-title {
      font-size: 13px;
    }

    .job-title-text-container {
      margin-top: 15px;
      font-family: "acumin-pro";
    }

    .testimonial-pfp {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 50px;
      min-height: 50px;
      max-height: 50px;
      border-radius: 50%;
      margin-right: 20px;
      margin-top: 20px;
    }

    .pfp-priscilla {
      background-image: url("../../images/people/Priscilla-Flake.jpg");
    }

    .pfp-billard {
      background-image: url("../../images/people/jesse-billard.JPG");
    }

    .pfp-good {
      background-image: url("../../images/people/Lindsay-Good.jpg");
    }

    .pfp-freeman {
      background-image: url("../../images/people/Judy-Freeman.jpg");
    }
  }
}

.careersPage-container .showcasePosition-section.section-noPaddingBottom {
  @include mixins.respond-min(constants.$breakpoint + 1) {
  }
}

.section-basicGrid {
  h2 {
    margin-top: 0;
  }

  h6 {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }

  .p-3 {
    max-width: 300px;
  }

  .benefits-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3em;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      grid-template-columns: 1fr;
    }

    h6 {
      margin-top: 15px;
    }
  }

  .benefits-grid_icon {
    width: 65px;
    height: 65px;
  }
}

.benefits-section {
  background-color: black;
  color: white;
  position: relative;
}

.section-basicGrid.benefits-section.benefits-section_paddingMobile {
  padding-bottom: 150px;

  @include mixins.respond-max(constants.$breakpoint) {
    padding-bottom: 460px;
  }
}

.careersPage-container .section-basicGrid.lifeAtFlueid-section {
  padding-top: 120px;
  background: linear-gradient(0deg, #f9f9f9 0%, #f4f4f4 100%);

  @include mixins.respond-max(constants.$breakpoint-xsm) {
    padding-top: 100px;
  }
}

.lifeAtFlueid-section {
  position: relative;
  padding-bottom: 0px !important;

  @include mixins.respond-max(450) {
  }

  .dashedline-spacing {
    margin-top: -30px !important;
    margin-left: 450px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-left: 200px;
    }

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      display: none;
    }
  }

  .txt-semiBold {
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;
  }

  p {
    max-width: 975px;
  }

  .icon-arrow_white {
    margin-left: 10px;
  }

  h1,
  .icon-infinity {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .icon-infinity {
    height: 20px;
    width: 90px;
    background-image: url("../../images/icons/icon-infinity.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 48px;
  }

  .subheader-para {
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 100px;
  }

  .subheader-para-small-margin {
    font-size: 20px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 40px;
  }

  .lifeAtFlueid-benefits-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    gap: 50px;
    margin-bottom: 100px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-bottom: 120px;
    }
  }

  .lifeAtFlueid-benefits-block-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: "acumin-pro";
  }

  .lifeAtFlueid-benefits-block-para {
    color: #888888;
    font-size: 20px;
    line-height: 32px;
    margin-top: 0;
  }

  .benfits-logo {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 15px;
  }

  .logo-culture {
    background-image: url("../../images/icons/icon-culture.svg");
  }

  .logo-salary {
    background-image: url("../../images/icons/icon-401k.svg");
  }

  .logo-pto {
    background-image: url("../../images/icons/icon-pto.svg");
  }

  .logo-health {
    background-image: url("../../images/icons/icon-health.svg");
  }

  .logo-remote {
    background-image: url("../../images/icons/icon-wfh.svg");
  }

  .logo-flex {
    background-image: url("../../images/icons/icon-flexwork.svg");
  }

  .lifeAtFlueid-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
    grid-row-gap: 6em;
    margin: 80px auto 0px auto;
    padding-bottom: 70px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      grid-template-columns: 1fr 1fr;
    }

    @include mixins.respond-max(600px) {
      grid-template-columns: 1fr;
      grid-row-gap: 1em;
      padding-bottom: 0px;
    }
  }

  .lifeAtFlueid-gridItem {
    padding: 20px;
  }

  .dashedLineGroup-1 {
    margin-top: -145px;
  }

  .dashedLineGroup-2 {
    margin-top: -100px;
    width: 75%;
  }
}

.valuesStandardsList {
  max-width: 900px;
  justify-content: space-between;
  flex-wrap: wrap;

  h3 {
    margin-bottom: 0;
  }
}

.valuesCol {
  margin-right: 5%;
}

.standardsCol {
  h6:not(:first-child):not(:nth-of-type(2)) {
    margin-top: 0;
  }

  h6:nth-of-type(2) {
    @include mixins.respond-max($standardsGrid-breakpoint) {
      margin-top: 0;
    }
  }

  .standardsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 1em;

    @include mixins.respond-max($standardsGrid-breakpoint) {
      grid-template-columns: 1fr;
    }
  }
}

.contactLink-section {
  text-align: center;

  h3 {
    margin-bottom: 0;
    @include fonts.abril-fatface;
  }

  .btn {
    margin: auto;
    margin-top: 50px;
  }

  .dashedLineGroup-1 {
    margin-top: -100px;
    width: 55%;
  }
}
