@use "../mixins";
@use "../constants";

$graphic-offset: 50px;

.join-section {
  padding-bottom: 100px;
  padding-top: $graphic-offset;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding-top: 0px;
  }

  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }
  }

  h3:first-child {
    font-family: "Abril Fatface";
    @include mixins.respond-max(constants.$breakpoint) {
      margin-top: constants.$section-mobilePadding-md;
    }
  }

  p {
    max-width: 444px;
  }

  .dashedLineGroup-1 {
    bottom: -50px;
    width: 70%;

    @include mixins.respond-max(constants.$breakpoint) {
      bottom: -75px;
    }
  }

  .graphic-joinTeam {
    background-image: url("../../images/home/photo-home.team@2x.jpg");
    @include mixins.imgCenterCover;
    width: 750px;
    height: 550px;
    position: relative;
    top: -$graphic-offset;
    margin-right: 50px;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 100vw;
      margin-right: 0;
      top: 0;
      background-position: right;
      height: 320px;
    }
  }

  .job-link-blue {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    @include mixins.textLink;
  }

  .arrow-learn-more {
    background-image: url("../../images/icons/arrow-right-blue.svg");
    background-position: center;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .arrow-row-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
