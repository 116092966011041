@use "../mixins";
@use "../constants";

.platform-section {
  padding: 200px 0;

  .section-content {
    position: relative;
  }

  .solar-wrapper {
    position: relative;
  }

  .solar-text {
    position: absolute;
    top: 0;
    z-index: 99;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      position: relative;
    }
  }

  .svg-icon {
    width: 100%;
    height: 100%;
  }

  .solarSystem {
    height: 167vw;
    left: -77%;
    max-height: 2070px;
    max-width: 2146px;
    position: relative;
    top: -285px;
    width: 155vw;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 155vw;
      left: -75%;
      height: 1637px;
      top: -172px;
    }

    .ss-group {
      opacity: 0;
      transition: opacity 1s ease-in;
    }

    &.active {
      .ss-group {
        opacity: 1;
      }

      .ss-group-2 {
        transition-delay: 0.5s;
      }

      .ss-group-3 {
        transition-delay: 1.5s;
      }

      .ss-group-4 {
        transition-delay: 2.5s;
      }

      .ss-group-5 {
        transition-delay: 3.5s;
      }
    }
  }

  .solarSystem_desktop {
    display: block;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .solarSystem_mobile {
    display: none;
    left: 50%;
    transform: translateX(-50%);
    width: 1800px;
    height: 3000px;
    top: -15vw;
    max-height: none;
    top: -1200px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: block;
    }
  }
}
