@mixin abril-fatface {
  font-family: "Abril Fatface";
}

@mixin acumin-pro {
  font-family: "acumin-pro";
}

@mixin truncateLine($lineClamp) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}
