@use "./fonts/mixins" as fonts;
@use "../constants";

%btn {
  display: block;
  border-radius: constants.$border-radius;
  border: 1px solid;
  font-size: 14px;
  @include fonts.acumin-pro;
  font-weight: 600;
  letter-spacing: -0.1px;
  padding: 10px 15px;
  text-align: center;
  cursor: pointer;
  transition: ease all 0.3s;
  box-shadow: none;
  outline: none;
  min-width: 110px;
  &:hover.btn-black {
    background-color: constants.$btn-black_hover;
  }

  &:hover.btn-white {
    background-color: constants.$btn-white_hover;
  }
}

.btn {
  @extend %btn;
}

.btn-black {
  background-color: black;
  color: white;
}

.btn-white {
  background-color: white;
}

.btn-white_borderGray {
  border-color: #e1e1e1;
}

.btn-darkGray {
  background-color: constants.$btn-black_hover;
  color: white;
  border: none;

  &:hover {
    background-color: #4c4c4c;
  }
}

.btn-transparent {
  background-color: transparent;
  color: white;
}

.btn-noBorder {
  border: none;
}

.btn-blue {
  color: white;
  background-color: constants.$btn-blue;
  &:hover {
    background-color: constants.$btn-blue_hover;
  }
}

%btn-arrow {
  background-color: #222222;
  color: #888888;
  cursor: pointer;
  padding: 5px;
  cursor: pointer;
  transition: ease all 0.3s;
  border: none;
  font-size: 25px;
  width: 45px;
  min-width: 45px;
  height: 45px;
  &:hover {
    color: white;
  }
}

.arrowButton-group {
  display: block;
}

.btn-arrow {
  @extend %btn-arrow;
}

.btn-arrow_prev.btn {
  @extend .btn-arrow;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-arrow_next:after,
.btn-arrow_prev:after {
  position: relative;
  bottom: 3px;
}

.btn-arrow_prev:after {
  content: "‹";
}

.btn-arrow_next.btn {
  @extend .btn-arrow;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-arrow_next:after {
  content: "›";
}
