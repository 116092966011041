@use "../mixins.scss" as m;
@use "../constants" as c;

$contact-grid-breakpoint: 630px;
$section-spacing: 150px;
$section-spacing-mobile: 100px;

.contactInfo-section {
  padding: $section-spacing 0;

  @include m.respond-max(c.$breakpoint) {
    padding: $section-spacing-mobile 0;
  }

  .content {
    width: c.$section-width;
    max-width: c.$section-maxWidth;
    margin: auto;
  }

  .flueidProTalktDes-officeSec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @include m.respond-max(c.$breakpoint) {
      grid-template-columns: 1fr 1fr;
    }

    @include m.respond-max($contact-grid-breakpoint) {
      grid-template-columns: 1fr;
    }

    .flueidProTalktDes-officeItem {
      .flueidProTalktDes-seperator {
        height: 3px;
        max-width: 300px;
        transform: scaleY(-1);
        background-color: #000000;
        margin-top: 50px;

        @include m.respond-max($contact-grid-breakpoint) {
          max-width: none;
        }

        @include m.respond-max(c.$breakpoint) {
          margin-top: 35px;
        }
      }

      .address {
        margin-top: 6px;
      }

      .sales {
        margin-top: 6px;
      }

      .flueidProTalktDes-locationDir {
        display: flex;
        margin-top: 6px;
        max-width: 280px;

        @include m.respond-max(c.$breakpoint) {
          width: 100%;
          max-width: 300px;
        }

        .p-2 {
          cursor: pointer;
          border-bottom: 1px solid #000000;
        }

        .p-2:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .row:last-child {
    margin-top: $section-spacing;

    @include m.respond-max(c.$breakpoint) {
      margin-top: $section-spacing-mobile;
    }
  }

  h2:first-child {
    margin-top: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  a {
    display: block;
  }

  .p-2 {
    margin-top: 20px;
  }

  .dashedLineGroup-1 {
    margin-top: 100px;
    width: 75%;
  }
}
