@use "sass:math";

$popup-padding: 15px;

.ui-popup-container {
  position: relative;

  .popup-trigger {
    cursor: pointer;
  }

  .popup {
    position: absolute;
    z-index: 50;
    top: 30px;
    left: -$popup-padding;
    background-color: #323232;
    padding: $popup-padding;
    color: white;
    border-radius: 3px;
    min-width: 150px;

    transition: 0.3s ease all;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &.open {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  .popup-option {
    cursor: pointer;
    border-bottom: 1px solid #4b4b4b;
    padding: $popup-padding 0;

    a {
      width: 100%;
      color: white;
      display: block;
      font-weight: 400;
    }
  }

  .popup-option:first-child {
    padding-top: 0;
  }

  .popup-option:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

$contact-padding: 12px;
.contactForm-popup {
  .popup {
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
    background-color: white;
    color: black;
    padding: $contact-padding;
    top: -5px;
    width: 100%;
  }

  .popup-option {
    border: none;
    font-weight: 500;
    padding: $contact-padding;
    margin-bottom: $contact-padding;
    position: relative;
  }

  .popup-option:hover {
    background-color: lavender;
  }

  .popup-option:first-child {
    padding-top: $contact-padding;
  }

  .popup-option:last-child {
    padding-bottom: $contact-padding;
    margin-bottom: 0;
  }

  .popup-option::after {
    content: "";
    position: absolute;
    width: 96.5%;
    height: 1px;
    background-color: #e5e5e5;
    left: $contact-padding;
    bottom: calc(-1 * $contact-padding / 2);
  }

  .popup-option:last-child::after {
    display: none;
  }
}
