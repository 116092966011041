@use "../mixins";
@use "../constants";

$form-margin: 32px;

.editorialRequest-form {
  position: sticky;
  top: $form-margin;
  width: 340px;
  margin: $form-margin;
  padding: $form-margin;
  border: 1px solid black;
  align-self: flex-start;
  height: auto;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);

  @include mixins.respond-max(constants.$breakpoint) {
    align-self: center;
    margin-top: 0;
    width: 80%;
    padding: 16px;
    position: relative;
    top: 0;
    margin: 0;
    margin-bottom: 90px;
  }
}
