.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-justify-spaceBetween {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
