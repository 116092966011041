@use "../constants" as c;
@use "../mixins" as m;

.builtByFlueid-section {
  padding-top: 150px;
  background-color: #1b4b99;
  padding-bottom: 80px;
  position: relative;
  overflow: hidden;

  .vot-graphic {
    position: absolute;
    top: -1rem;
    width: 100%;
    max-height: 460px;
    z-index: 0;
  }

  .flueid-decision-header-spacing {
    margin-top: 3rem;
  }

  div {
    color: white;
  }

  .dot-pattern {
    width: 79%;
    height: 40px;
    background-size: contain;
    position: absolute;
    top: 0;
    max-width: 2238px;

    @include m.respond-max(c.$breakpoint) {
      background-size: cover;
      position: relative;
      width: 100%;
    }
  }

  .photo-hero {
    display: none;
  }

  .content {
    margin: auto;
    max-width: c.$section-maxWidth-small;
    width: c.$section-width;

    @include m.respond-max(950px) {
    }
  }

  .desktop-screens {
    position: relative;
    padding-top: 1px;

    @include m.respond-max(950px) {
      display: none;
    }
  }

  .subheader-opacity {
    opacity: 0.75;
  }

  .paragraph-mb {
    margin-bottom: 6rem;
  }

  .btns-container {
    position: relative;
    width: 106%;
    margin-top: 30px;
    margin-bottom: 100px;
    display: flex;

    @include m.respond-max(950px) {
      height: auto;
    }

    @include m.respond-max(950px) {
      flex-direction: column;
    }

    @include m.respond-max(c.$breakpoint) {
      width: 100%;
    }
  }

  .slides-header {
    font-size: 24px;
    font-weight: normal;
  }

  .vot-block {
    width: 50%;
  }

  .vot-header {
    font-size: 32px;
    font-weight: 200;
    font-style: normal;
    margin-top: 6rem;
  }

  .vot-p {
    max-width: 100%;
  }

  .btn:first-child {
    margin-bottom: 15px;
  }

  .flueid-photo-lg {
    background-image: url("../../images/home/order-progress-graphic@2x.jpg");
    background-size: cover;
    height: 750px;
    min-height: 500px;
    width: 51%;
    position: absolute;
    right: -10%;
    top: 40%;

    @include m.respond-max(950px) {
      position: relative;
      width: 90%;
    }

    @include m.respond-range(950px, c.$breakpoint) {
      margin-right: -50vw;
      max-width: 100vw;
      position: relative;
      right: 50%;
      width: 100vw;
    }
  }

  .photo-lenders {
    background-image: url("../../images/home/graphic-forLenders@2x-min.png");
  }

  .photo-agents {
    background-image: url("../../images/home/graphic-forTitle@2x-min.png");
  }

  .photo-UW {
    background-image: url("../../images/home/graphic-forUnderwriter@2x-min.png");
  }

  .photo-real-estate {
    background-image: url("../../images/home/graphic-forREP@2x-min.png");
  }

  .photo-servicers {
    background-image: url("../../images/graphics/Website_Sample_servicer.png");
  }

  .photo-lossmit {
    background-image: url("../../images/graphics/Website_Sample_LossMit.png");
  }

  h2:first-child {
    @include m.respond-max(c.$breakpoint) {
      margin-top: c.$section-mobilePadding-xl;
    }
  }

  p {
    @include m.respond-min(c.$breakpoint) {
      max-width: 80%;
    }

    @include m.respond-max(c.$breakpoint-sm) {
      max-width: none;
    }
  }

  .dot-pattern-wide {
    background-size: cover;
    position: absolute;
    z-index: 3;
    width: 150px;
    height: 932px;
    right: 0%;
    top: -156px;

    @include m.respond-max(c.$breakpoint) {
      height: 90vw;
      right: -6%;
    }

    @include m.respond-max(c.$breakpoint-sm) {
      display: none;
    }
  }

  .icon-svg_arrow {
    margin-left: 10px;
  }
}

.small-banner-mobile {
  background-color: #0070c0;

  @include m.respond-min(c.$breakpoint-sm) {
    display: none;
  }
}

.banner-left {
  background-color: #1b94ea;
  padding: 70px 50px 50px 50px;
  flex: 1 1;
}

.banner-right {
  background-color: #0070c0;
  padding: 140px 50px 50px 50px;
  flex: 1 1;

  @include m.respond-max(1023px) {
    padding-top: 20px;
  }
}

.banner-flex {
  display: flex;

  @include m.respond-max(1023px) {
    flex-direction: column;
  }
}

.inc5000-banner-small {
  z-index: 100;

  .btn-learn-more {
    margin-top: 20px;
    border: 1px solid white;
    cursor: pointer;
    width: 150px;
  }

  .inc5000banner-content {
    justify-content: space-around;

    padding-bottom: 30px;
    max-width: 600px;

    @include m.respond-min(1023px) {
      margin-left: 30px;
    }
  }

  .inc5000banner-content-left {
    @include m.respond-min(1023px) {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .banner-txt {
    color: white;
  }

  .banner-txt-small {
    font-weight: 600;
    padding-top: 30px;
    padding-bottom: 20px;

    @include m.respond-max(c.$breakpoint-sm) {
      padding-top: 100px;
    }
  }

  .banner-txt-large {
    font-size: 36px;
    font-family: "abril fatface";

    @include m.respond-max(c.$breakpoint-sm) {
      font-size: 28px;
    }
  }

  .banner-txt-medium {
    font-size: 20px;
    padding-bottom: 20px;
    line-height: 35px;

    @include m.respond-max(c.$breakpoint-sm) {
      font-size: 16px;
    }
  }

  .banner-txt-subtitle {
    font-size: 28px;

    @include m.respond-max(c.$breakpoint-sm) {
      font-size: 20px;
    }
  }

  .subtitle-right-padding {
    padding-top: 55px;
  }

  .para-right-padding {
    padding-top: 15px;
  }

  .marginleft {
    margin-left: 15px;
  }

  .banner-text-container {
    margin: auto;
    margin-top: 20px;
  }

  .text-hidden-mobile {
    display: none;
  }

  .text-container-mobile {
    width: 60%;
    margin-left: 240px;
  }
}

.background-banner {
  background-color: #f9f9f9;
}

.banner-left-spacing-title {
  padding-bottom: 30px;
  padding-top: 50px;
}

.banner-txt-spacing-subtitle {
  padding-bottom: 10px;
}

.txt-large {
  padding-top: 60px;
  line-height: 40px;

  @include m.respond-max(c.$breakpoint) {
    padding-top: 0px;
  }
}

.logos-grid-container {
  margin-left: 138px;
  display: flex;
  flex-wrap: wrap;

  @include m.respond-range(1150px, 1300px) {
    grid-gap: 0% 5%;
    margin-right: 5%;
  }

  @include m.respond-max(1300px) {
    margin-left: 55px;
  }

  @include m.respond-max(1150px) {
    grid-gap: 0% 10px;
    margin-right: 0px;
  }

  @include m.respond-max(c.$breakpoint) {
    flex-direction: column;
    margin-left: 0;
  }
}

.schedule-meeting-container {
  margin-left: 188px;

  @include m.respond-max(1300px) {
    width: 100%;
    margin-left: 105px;
  }

  @include m.respond-max(c.$breakpoint) {
    margin: 0;
  }
}

.img-logo {
  background-size: contain;
  background-repeat: no-repeat;
  width: 150px;
  height: 70px;
  margin: 30px 50px;

  @include m.respond-max(c.$breakpoint) {
    width: 190px;
    margin-left: auto;
    margin-right: auto;
  }
}

.img-encompass {
  background-image: url("../../images/logos/logo-encompass.png");
  background-position: center;
  width: 222px;
}

.img-blend {
  background-image: url("../../images/logos/logo-blend.png");
}

.img-firstclose {
  background-image: url("../../images/logos/logo-firstclose.png");
  width: 220px;
}

.img-resware {
  background-image: url("../../images/logos/logo-resware.png");
  width: 222px;

  @include m.respond-max(c.$breakpoint) {
    margin-left: auto;
    margin-right: auto;
  }
}

.meeting-header {
  font-size: 22px;
  line-height: 24px;
}

.demo-link {
  display: block;
  width: max-content;
}

.meeting-subheader {
  margin-top: 0px !important;
  line-height: 24px;
}

.subheader-maxwidth {
  max-width: 40%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 185px;

  @include m.respond-max(c.$breakpoint-sm) {
    max-width: 100%;
  }

  @include m.respond-max(1300px) {
    margin-left: 100px;
  }

  @include m.respond-max(c.$breakpoint) {
    margin-left: 0px;
  }

  @include m.respond-range(600px, c.$breakpoint) {
    max-width: 450px !important;
    margin: 0 auto 1rem;
  }
}

.integrations-section {
  max-width: 1600px !important;

  @include m.respond-range(600px, c.$breakpoint) {
    max-width: 450px !important;
    margin: 0 auto;
  }
}

.integrations-header {
  margin-left: 185px;

  @include m.respond-max(1300px) {
    margin-left: 100px;
  }

  @include m.respond-max(c.$breakpoint) {
    margin-left: 0px;
  }
}

.section-padding {
  padding-top: 80px;
}

.btn-margin-top {
  margin-top: 50px;
}

.decision-header {
  width: 80%;
  margin-top: 0px;

  @include m.respond-max(c.$breakpoint) {
    width: 100%;
  }
}

.decision-graphic-section {
  display: flex;
  width: 65%;

  @include m.respond-max(950px) {
    width: 100%;
    justify-content: space-between;
  }

  @include m.respond-max(500px) {
    flex-direction: column;
  }
}

.decision-left-section {
  width: 40%;

  @include m.respond-max(950px) {
    width: 90%;
    margin-right: 30px;
  }

  p {
    opacity: 0.9;
  }
}

.decision-right-section {
  width: 38%;

  @include m.respond-max(950px) {
    width: 100%;
  }

  h3 {
    margin-top: -10px;
  }

  p {
    max-width: 100%;
    font-size: 16px;
    line-height: 30px;
    opacity: 0.9;
  }
}

.decision-subtitles {
  cursor: pointer;
  width: 170px;
  margin: 0px 0px 20px 0px;
  font-size: 16px;
  line-height: 20px;
  opacity: 0.75;
}

.active-builtsection {
  text-decoration: underline;
  opacity: 1;
}

.active-arrow {
  background-image: url("../../images/icons/arrow-right.svg");
  height: 20px;
  width: 20px;
  background-size: contain;
  margin-left: 100px;
  background-repeat: no-repeat;
  margin-top: 0px;

  @include m.respond-max(1250px) {
    margin-left: 10px;
    margin-right: 20px;
  }

  @include m.respond-max(500px) {
    display: none;
  }
}

.arrow-row-container {
  display: flex;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -100px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;

  @include m.respond-max(1100px) {
    min-height: 40px;
    max-height: 50px;
  }
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
    max-height: 50px;
  }
}

.flueid-decision-mobile {
  @include m.respond-min(950px) {
    display: none;
  }

  .mobile-width {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include m.respond-max(950px) {
    }
  }

  h3 {
    font-size: 24px;
    font-style: italic;
    margin-top: 60px;
  }

  .subparagraph {
    padding-bottom: 40px;
  }

  .slick-slider {
    height: 600px;

    @include m.respond-min(c.$breakpoint-sm) {
      height: 700px;
    }

    @include m.respond-max(c.$breakpoint-sm) {
      height: 750px;
    }

    @include m.respond-max(400px) {
      height: fit-content;
    }

    .slick-dots {
      margin-bottom: 100px;

      @include m.respond-max(450px) {
        margin-bottom: 15px;
      }
    }
    .slick-prev {
      margin: 3% 0 auto 90px;
      z-index: 9999;

      @include m.respond-max(c.$breakpoint-sm) {
        display: none;
      }
    }
    .slick-next {
      margin: 3% 90px auto 0;

      @include m.respond-max(c.$breakpoint-sm) {
        display: none;
      }
    }
  }

  .txt-subheader {
    padding-top: 0px;
    margin-top: 0px;
  }

  .slider-header {
    font-size: 28px;
    font-weight: 600;
  }

  .slider-subheader {
    font-size: 16px;
    padding: 15px 0px 60px 0px;
    line-height: 30px;
  }

  .decision-img-top {
    height: 170px;
    width: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    margin-right: 45%;

    @include m.respond-max(550px) {
      margin-left: 0;
    }

    @include m.respond-range(500px, 950px) {
      margin: 0 auto;
      height: 400px;
      width: 65%;
    }

    @include m.respond-range(500px, 650px) {
      width: 80%;
    }

    @include m.respond-max(500px) {
      height: 174px;
      width: 286px;
    }
  }

  .decision-img-bottom {
    height: 200px;
    width: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;

    @include m.respond-max(650px) {
      margin-right: -8%;
    }

    @include m.respond-range(500px, 950px) {
      display: none;
    }

    @include m.respond-max(500px) {
      height: 179px;
      width: 308px;
      margin-right: 0%;
    }
  }

  .photo-slider1 {
    background-image: url("../../images/graphics/loan-slide1.png");

    @include m.respond-range(500px, 950px) {
      background-image: url("../../images/graphics/graphic-forLenders-tablet@2x-min.png");
    }
  }

  .photo-slider-servicers {
    background-image: url("../../images/graphics/Website_mobile_servicer.png");
    background-position: center;

    @include m.respond-max(500px) {
      margin: 0 auto;
      width: 75vw;
      height: 300px;
    }
  }

  .photo-slider2 {
    background-image: url("../../images/graphics/graphic-titleAgent-card.png");

    @include m.respond-range(500px, 950px) {
      background-image: url("../../images/graphics/graphic-forTitle-tablet@2x-min.png");
    }
  }

  .photo-slider3 {
    background-image: url("../../images/graphics/graphic-underwriter-card.png");

    @include m.respond-range(500px, 950px) {
      background-image: url("../../images/graphics/graphic-forUnderwriter-tablet@2x-min.png");
    }
  }

  .photo-slider4 {
    background-image: url("../../images/graphics/graphic-buyer-card.png");

    @include m.respond-range(500px, 950px) {
      background-image: url("../../images/graphics/graphic-forREP-tablet@2x-min.png");
    }
  }

  .photo-slider5 {
    background-image: url("../../images/graphics/graphic-borrower-card.png");

    @include m.respond-range(500px, 950px) {
      background-image: url("../../images/graphics/graphic-forServicer-tablet@2x-min.png");
    }
  }

  .photo-slide-bottom1 {
    background-image: url("../../images/graphics/borrower-slide1.png");
  }

  .photo-slide-bottom2 {
    background-image: url("../../images/graphics/graphic-loanProcessor-card.png");
  }

  .photo-slide-bottom3 {
    background-image: url("../../images/graphics/graphic-title-card.png");
  }

  .photo-slide-bottom4 {
    background-image: url("../../images/graphics/graphic-listingAgent-card.png");
  }

  .photo-slide-bottom5 {
    background-image: url("../../images/graphics/graphic-servicer-card.png");
  }
}
