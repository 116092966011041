@use "../common/fonts/mixins" as fonts;
@use "../mixins";
@use "../constants";
@use "./constants" as aboutConstants;

.testimony-section {
  color: white;
  background-color: black;
  padding: aboutConstants.$section-padding 0;
  @include aboutConstants.sectionPaddingMobile(constants.$breakpoint-sm);

  .slick-track {
    display: flex;
  }

  .slick-slide {
    align-self: flex-end;
  }

  .slick-slide > div {
    display: flex;
    height: 100%;
  }

  .section-content {
    max-width: 775px;
  }

  .testimony-pagination-block {
    position: relative;
  }

  .arrowButton-group {
    position: absolute;
    right: 0;
    bottom: 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: -50px;
      right: 50%;
      transform: translateX(50%);
      position: relative;
    }
  }

  h3 {
    @include fonts.abril-fatface;
    text-align: center;
    margin-top: 175px;
  }

  .dashedLineGroup-1 {
    margin-top: 100px;
    width: 100%;
    left: -10%;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: 130px;
      width: 85%;
      left: -4%;
    }
  }
}

.testimony-section_white {
  background: white;
  color: black;
}

.testimonyCard {
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .testimonyCard-person {
    transition: ease all 1s;
    align-items: flex-end;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .testimony-photo {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.testimonyCard:nth-child(2) {
  margin: 0 40px;
}

.testimonyCard.active {
  .testimonyCard-person {
    background-color: black;
    color: white;
  }
}
