@use "../mixins.scss";
@use "../constants";

.numbers-section {
  background: linear-gradient(0deg, rgba(249, 249, 249, 0) 0%, rgba(244, 244, 244, 1) 100%);
  padding: 100px 0;

  @include mixins.respond-max(constants.$breakpoint) {
    padding: constants.$section-mobilePadding-md 0;
  }

  h3 {
    max-width: 469px;
    margin-top: -15px;
    margin-bottom: 50px;
  }

  .p-1 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .numbers-block-text {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
  }

  .numbersGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2px;

    @include mixins.respond-max(constants.$breakpoint) {
      grid-template-columns: repeat(1, 1fr);
    }

    @include mixins.respond-max(550px) {
      grid-template-columns: 1fr;
    }
  }

  .numbersGrid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 40px 70px;
    background-color: white;

    @include mixins.respond-max(constants.$breakpoint) {
      padding-bottom: 30px;
    }
  }

  .dashedLineGroup-1 {
    margin-top: calc(#{constants.$section-mobilePadding-md} * 2);
    width: 80%;
  }

  .line-separator {
    width: 40px;
    height: 1px;
    background-color: black;
    margin: 35px 0 20px;
  }

  .btn-black {
    width: 195px;
    border-radius: 31.99px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    pointer-events: none;
  }

  .btn-text_maxWidth {
    max-width: 175px;
  }

  .quote-container {
    max-width: 825px;
    margin: auto;
    margin-top: 200px;
    margin-bottom: 100px;
    position: relative;

    @include mixins.respond-max(constants.$breakpoint) {
      margin-bottom: constants.$section-mobilePadding-md;
      padding-left: 0;
      width: 85%;
    }
  }

  .quoteAuthor-block {
    align-items: flex-end;
    text-align: right;
    margin-top: 60px;
  }

  .photo-dufficy {
    width: 100px;
    height: 100px;
    background-image: url("../../images/people/photo-dufficy.jpg");
    @include mixins.imgCenterContain;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .quote-author,
  .quote-authorTitle {
    font-size: 16px;
    line-height: 20px;
  }

  .quote-authorTitle {
    color: #4b4b4b;
  }

  .txt-align-center {
    text-align: center;
    margin-bottom: 20px;
  }

  .text-subheader {
    margin-bottom: 50px;
    margin-top: 0px;
  }

  .img-numbers-section {
    width: 300px;

    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
  }

  .numbers-img-flex {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    vertical-align: middle;
    padding-top: 100px;

    @include mixins.respond-max(constants.$breakpoint-md) {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 0px;
    }
  }

  .img-tag {
    position: relative;
    top: 20px;
    font-weight: 500;

    @include mixins.respond-max(constants.$breakpoint-md) {
      display: none;
    }
  }

  .img-mortgage {
    height: 160px;
    background-image: url("../../images/logos/2024_Tech100_logo_Black-01.png");
    margin: 20px auto;
  }
}
