@use "../constants";
@use "../mixins";

.sec-quotes-section {
  padding: 125px 0 150px;
  position: relative;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 50px 0 100px;
  }

  .h-max-width {
    max-width: 485px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      max-width: none;
    }
  }

  .p-max-width {
    max-width: 625px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      max-width: none;
    }
  }
}
