@use "../constants";
@use "../mixins";

.sec-prod-dev-section {
  padding: 75px 0;
  background-color: white;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 32px 0;
  }

  .relative {
    position: relative;
  }

  .icon-cloud {
    position: absolute;
    right: 0;
    top: -2rem;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      display: none;
    }
  }

  h3,
  p {
    position: relative;
  }

  .p-max-width {
    max-width: 666px;
  }

  .product-details-row {
    display: flex;
    gap: 2rem;
    margin: 4rem 0;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin: 2rem 0;
      gap: 1rem;
    }
  }

  .product-details-row.mb-0 {
    margin-bottom: 0;
  }

  .product-detail-card {
    display: flex;
    position: relative;
    height: 100%;

    p {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  .product-detail-card-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -8px;
    left: -8px;
    background: radial-gradient(gray -1px, transparent 1px) 4px 6px/3px 3px;
    border-radius: 16px;
  }

  .product-detail-content {
    display: flex;
    gap: 2rem;
    width: 100%;
    position: relative;
    padding: 2rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 7%);

    @include mixins.respond-max(constants.$breakpoint-sm) {
      gap: 1rem;
    }
  }
}
