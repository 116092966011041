@use "../common/fonts/mixins";

.newsletter-section {
  text-align: center;

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    @include mixins.abril-fatface;
  }

  h6 {
    max-width: 520px;
    margin-top: -20px;
    margin-bottom: 60px;
  }

  .p-4 {
    text-align: left;
    margin-top: 20px;
    max-width: 300px;
  }

  .input-container {
    width: 100%;
  }

  .input-group {
    flex: 1;
  }

  .btn {
    height: fit-content;
    margin-left: 15px;
  }

  .dashedLineGroup-1 {
    margin-top: -100px;
    width: 75%;
  }
}
