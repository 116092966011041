@use "../mixins";
@use "../constants";

.break-section {
  background-color: black;

  .breaksection-text {
    color: white;
    font-size: 32px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding-top: 80px;
    max-width: 1200px;
    @include mixins.respond-max(630px) {
      font-size: 24px;
      line-height: 32px;
    }
    @include mixins.respond-max(600px) {
      width: 90%;
    }
  }
  .arrow-icon {
    background-image: url("../../images/icons/arrow-down.png");
    width: 70px;
    height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
    padding-bottom: 80px;
  }
}
