@use "../mixins";
@use "../constants";
@use "../common/fonts/mixins" as fonts;
@use "./constants" as aboutConstants;

.team-section {
  padding: aboutConstants.$section-padding 0;
  @include aboutConstants.sectionPaddingMobile(constants.$breakpoint-sm);
  background: linear-gradient(180deg, rgba(253, 253, 253, 0) 0%, #f9f9f9 100%);

  h5 {
    max-width: 915px;
  }

  .teamMember-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    margin: 100px 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .slick-slider {
    display: none;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: block;
      margin: 100px 0;
    }
  }

  .icon-linkedIn {
    display: block;
    margin: auto;
  }

  .btn-black {
    margin: auto;
    margin-bottom: 150px;
  }

  h3 {
    margin: 50px 0 25px;
  }
}
