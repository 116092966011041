@use "../mixins";
@use "../constants";

.services-container-loss-mit {
  background-color: #ffffff;
  padding-bottom: 80px;

  .services-flex-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    gap: 50px;

    max-width: 1400px;
    margin: 0 auto;
  }

  .services-flex-block {
    max-width: 375px;
    margin: 0 auto;

    @include mixins.respond-max(700px) {
      width: 90%;
    }
  }

  .services-block-title {
    color: #0070c0;
    font-size: 20px;
    font-weight: 700;
    font-family: "acumin-pro";
  }

  .services-title {
    font-size: 36px;
    margin: 80px auto;
    text-align: center;
    padding-top: 80px;
    margin-top: 0px;
    font-family: "acumin-pro";
    font-weight: 300;
  }

  .text-blue {
    font-family: "acumin-pro";
  }

  .services-block-text {
    margin-top: 10px;
    line-height: 24px;
    font-family: "acumin-pro";
  }

  .report-bottom-text {
    color: black;
    text-align: center;
    margin-top: 80px;
  }
}
