.p-0 {
  padding: 0;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}
