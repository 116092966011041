@use "../mixins";
@use "../constants";

$section-padding_large: 200px;
$section-padding: 100px;
$section-padding_mobile: 50px;

@mixin sectionPaddingMobile($width) {
  @include mixins.respond-max($width) {
    padding: $section-padding_mobile 0;
  }
}
