@use "../constants";

.margin-auto {
  margin: auto;
}

.margin-none {
  margin: 0;
}

.margin_xxsm {
  margin: constants.$margin_xxsm;
}

.margin_xsm {
  margin: constants.$margin-xsm;
}

.margin_sm {
  margin: constants.$margin-sm;
}

.margin_md {
  margin: constants.$margin-md;
}

.margin_lg {
  margin: constants.$margin-lg;
}

.margin_xl {
  margin: constants.$margin-xl;
}

.m-4 {
  margin: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

/* -------------- */
/* Margin Bottom */
.margin-bottom_none {
  margin-bottom: 0;
}

.margin-bottom_xxsm {
  margin-bottom: constants.$margin_xxsm;
}

.margin-bottom_xsm {
  margin-bottom: constants.$margin-xsm;
}

.margin-bottom_sm {
  margin-bottom: constants.$margin-sm;
}

.margin-bottom_md {
  margin-bottom: constants.$margin-md;
}

.margin-bottom_lg {
  margin-bottom: constants.$margin-lg;
}

.margin-bottom_xl {
  margin-bottom: constants.$margin-xl;
}
/* ------------- */

/* --------------*/
/* Margin Top */
.margin-top_none {
  margin-top: 0;
}

.margin-top_xxsm {
  margin-top: constants.$margin_xxsm;
}

.margin-top_xsm {
  margin-top: constants.$margin-xsm;
}

.margin-top_sm {
  margin-top: constants.$margin-sm;
}

.margin-top_md {
  margin-top: constants.$margin-md;
}

.margin-top_lg {
  margin-top: constants.$margin-lg;
}

.margin-top_xl {
  margin-top: constants.$margin-xl;
}
/* --------------*/

/* --------------*/
/* Margin Right */
.margin-right_none {
  margin-right: 0;
}

.margin-right_xxsm {
  margin-right: constants.$margin_xxsm;
}

.margin-right_xsm {
  margin-right: constants.$margin-xsm;
}

.margin-right_sm {
  margin-right: constants.$margin-sm;
}

.margin-right_md {
  margin-right: constants.$margin-md;
}

.margin-right_lg {
  margin-right: constants.$margin-lg;
}

.margin-right_xl {
  margin-right: constants.$margin-xl;
}
/* --------------*/

/* --------------*/
/* Margin Left */
.margin-left_none {
  margin-left: 0;
}

.margin-left_xxsm {
  margin-left: constants.$margin_xxsm;
}

.margin-left_xsm {
  margin-left: constants.$margin-xsm;
}

.margin-left_sm {
  margin-left: constants.$margin-sm;
}

.margin-left_md {
  margin-left: constants.$margin-md;
}

.margin-left_lg {
  margin-left: constants.$margin-lg;
}

.margin-left_xl {
  margin-left: constants.$margin-xl;
}
