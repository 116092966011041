.teamMember-card {
  display: block;
  text-align: center;

  .teamMember-photo {
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    margin: auto;
    width: 140px;
    height: 140px;
  }

  h5 {
    margin-bottom: 0;
    font-size: 20px;
  }

  .p-2 {
    margin-bottom: 15px;
  }

  .linkedIn-link {
    padding: 2px;
    border: 2px solid black;
    font-size: 12px;
  }
}
