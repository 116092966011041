@use "../mixins";
@use "../constants";

.graph-card-container {
  position: relative;

  .graph-text {
    width: 230px;
    position: absolute;
    top: 200px;
    left: 42%;
    font-size: 18px;
    line-height: 24px;

    @include mixins.respond-min(900px) {
      left: 41%;
    }

    @include mixins.respond-min(1300px) {
      left: 43%;
    }

    @include mixins.respond-min(1600px) {
      left: 44%;
    }

    @include mixins.respond-min(2000px) {
      left: 45%;
    }

    @include mixins.respond-min(2200px) {
      left: 46%;
    }

    @include mixins.respond-min(2800px) {
      left: 47%;
    }

    @include mixins.respond-max(900px) {
      display: none;
    }
  }

  .graph-img-desktop {
    @include mixins.respond-max(900px) {
      display: none;
    }
  }

  .graph-img-mobile {
    @include mixins.respond-min(900px) {
      display: none;
    }
  }

  .graph-right-text {
    position: absolute;
    font-size: 14px;
    right: 60px;

    @include mixins.respond-min(1500px) {
      right: 80px;
    }

    @include mixins.respond-min(2100px) {
      right: 100px;
    }

    @include mixins.respond-max(900px) {
      display: none;
    }
  }

  .right-text1 {
    top: 188px;

    @include mixins.respond-max(1200px) {
      right: 55%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text2 {
    top: 208px;

    @include mixins.respond-max(1200px) {
      right: 52%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text3 {
    top: 226px;

    @include mixins.respond-max(1200px) {
      right: 51%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text4 {
    top: 247px;

    @include mixins.respond-max(1200px) {
      right: 48%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text5 {
    top: 268px;

    @include mixins.respond-max(1200px) {
      right: 45%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text6 {
    top: 288px;

    @include mixins.respond-max(1200px) {
      right: 40%;
      top: unset;
      bottom: -40px;
    }
  }

  .right-text7 {
    top: 308px;

    @include mixins.respond-max(1200px) {
      right: 37%;
      top: unset;
      bottom: -40px;
    }

    @include mixins.respond-max(900px) {
      right: 33%;
    }

    @include mixins.respond-max(650px) {
      right: 27%;
    }

    @include mixins.respond-max(480px) {
      right: 17%;
    }
  }
}

.graph-section {
  background-color: #f4f4f4;
  padding-bottom: 80px;
  @include mixins.respond-max(900px) {
    padding-bottom: 40px;
  }

  .graph-header {
    font-size: 36px;
    text-align: center;
    width: 65%;
    margin: 0px auto;
    margin-bottom: 20px;
    padding-top: 90px;
    font-family: "acumin-pro";
    height: 80px;

    @include mixins.respond-min(900px) {
      width: 90%;
      height: 110px;
    }

    @include mixins.respond-max(900px) {
      width: 90%;
      height: 150px;
    }

    @include mixins.respond-max(800px) {
      height: 120px;
      font-size: 28px;
      margin-bottom: 0px;
    }
  }

  .button-section-desktop {
    @include mixins.respond-max(900px) {
      display: none;
    }
  }

  .main-content-container {
    .slick-slider {
      .slick-dots {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-end;
        width: 3%;
        right: 20px;
        bottom: 220px;

        @include mixins.respond-max(1200px) {
          flex-direction: row;
          justify-content: center;
          right: unset;
          bottom: unset;
          width: 100%;
        }

        @include mixins.respond-max(600px) {
          display: none !important;
        }
      }
    }
  }

  #sticky-section {
    position: sticky;
    top: 0;
  }

  #scroll-sections {
    position: relative;
    z-index: 500;

    @include mixins.respond-min(600px) {
    }
  }

  .scroll-item {
    width: 80%;
    border: 5px solid #c9dfe7;
    border-radius: 10px;
    margin: 0px 4% 100vh 4%;
    background-color: rgba($color: #ffffff, $alpha: 0.97);
    padding: 5%;
    opacity: 0.5;
    box-shadow: 0 0 10px #c9dfe7;

    @include mixins.respond-min(900px) {
      opacity: 0;
      padding: 0px;
      margin: 0px;
      height: 1px;
      border: 0px;
    }
    &.active {
      opacity: 1;

      @include mixins.respond-min(900px) {
        opacity: 0;
      }
    }
  }

  .graph-text-bottom {
    text-align: center;
    font-size: 20px;

    @include mixins.respond-max(1200px) {
      margin-top: 50px;
    }

    @include mixins.respond-max(700px) {
      width: 90%;
      margin: 0 auto;
      margin-top: 50px;
    }
  }

  .bottom-text-bold {
    font-weight: 700;
  }
}
.button-graph-section-mobile {
  z-index: 700;
  position: sticky;

  padding-bottom: 80px;
  background-color: #f4f4f4;
  @include mixins.respond-min(900px) {
    display: none;
  }
  .graph-text-bottom {
    text-align: center;
    font-size: 20px;

    @include mixins.respond-max(700px) {
      width: 90%;
      margin: 0 auto;
    }
  }
}
