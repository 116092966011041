.btn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  display: block;
  opacity: 0.5;
  padding: 0;
}

.btn_active {
  opacity: 1;
}

.pi {
  transform: rotate(-90deg); /* Fix the orientation */
}

.label {
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
