@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.3s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@mixin fadeInLeft($time) {
  animation: fadeInLeft $time;
}

.fadeInLeft {
  animation: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation: fadeInRight;
}

.hidden {
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpLong {
  animation-name: fadeInUpLong;
}

@keyframes fadeInUpLong {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation: fadeOut 0.3s;
  animation-fill-mode: forwards;
}

@keyframes fadeOutHalf {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

.fadeOut {
  animation: fadeOutHalf 0.3s;
  animation-fill-mode: forwards;
}
