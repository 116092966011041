@use "../mixins";
@use "../constants";

$checkmark-size: 1000px;

.solutions-section {
  background-color: constants.$security-green;
  color: white;
  position: relative;
  padding-bottom: 110px;

  .trust-img {
    background-image: url("../../images/graphics/graphic-trust.svg");
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 280px;
    opacity: 0.5;
    position: absolute;
    z-index: 0;
    bottom: -20px;

    @include mixins.respond-max(950px) {
      height: 150px;
    }
  }

  .security-header {
    text-align: center;
    padding-top: 100px;
    position: relative;
    z-index: 10;
    margin-bottom: 0px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      width: 90%;
      margin: 0 auto;
      text-align: left;
    }
  }

  .arrow-row-container {
    position: relative;
    z-index: 10;
  }

  .mobile-width {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .security-subheader {
    text-align: center;
    position: relative;
    z-index: 10;
    margin: 0 auto;
    margin-top: 20px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      text-align: left;
    }
  }

  .card {
    padding: 38px 36px 38px 7%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 30em;
    width: 100%;

    @include mixins.respond-max(1600px) {
      padding: 38px 36px 38px 3%;
    }

    @include mixins.respond-max(1380px) {
      padding: 38px 36px 38px 1%;
    }

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      padding: 0px;
    }
  }

  .security-flex {
    display: flex;
    position: relative;
    z-index: 1;
    max-width: 1600px;
    margin: 0 auto;

    @include mixins.respond-max(1310px) {
      flex-direction: column-reverse;
    }

    .card {
      margin-left: 10%;

      @include mixins.respond-max(1310px) {
        margin: 0 auto;
        right: 0px;
        max-width: 90%;
        margin-top: -200px;
      }

      details {
        summary {
          padding: 20px;
        }

        p {
          margin-left: 60px;
        }
      }
    }
  }

  .security-right {
    height: 800px;
    position: absolute;
    z-index: 1;
    right: 15%;
    width: 450px;
    top: -250px;
    background: linear-gradient(transparent 15%, #6ab369);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 60px), 0 100%, 0 0);

    @include mixins.respond-max(1310px) {
      position: relative;
      margin: 0 auto;
      right: 0px;
    }

    @include mixins.respond-max(1310px) {
      width: 50%;
    }

    @include mixins.respond-max(600px) {
      width: 90%;
    }

    h2 {
      text-align: center;
      color: black;
      font-size: 80px;
      padding-top: 40px;
      border-top: 1px solid black;
      margin: 0 auto;
      margin-bottom: 0px;

      width: 60%;
    }

    p {
      text-align: center;
      color: black;
      font-size: 16px;
    }

    .aicpa-img {
      height: 200px;
      width: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../images/logos/logo-soc.png");
      margin: 0 auto;
      margin-top: 320px;
      padding-bottom: 40px;
    }
  }

  .waves-container {
    transform: rotate(180deg);

    .waves {
      margin-bottom: -5px;

      @include mixins.respond-max(1100px) {
        max-height: 50px;
        min-height: 50px;
      }
    }
  }

  .explore-text {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    color: white;

    @include mixins.textLink;
  }

  .text-link {
    &:hover {
      text-decoration: underline;
      font-weight: 700;
    }
  }

  .arrow-learn-more {
    background-image: url("../../images/icons/arrow-right.svg");
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    margin-top: 28px;
    margin-left: 15px;
  }

  .demo-link {
    display: flex;
    width: max-content;
  }
}
