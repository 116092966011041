@use "../common/fonts/mixins" as fonts;
@use "../mixins";
@use "../constants";

.featuredStory-container {
  .section-content {
    padding-bottom: 100px;
  }

  .section-paddingBottom_small {
    padding-bottom: 30px;
  }

  .section-paddingBottom_md {
    padding-bottom: 50px;
  }

  .editorial-body a {
    text-decoration: underline;
    color: constants.$btn-blue;
  }

  h2 {
    margin-top: 15px;
    margin-bottom: 30px;
    @include mixins.respond-max(constants.$breakpoint-xsm) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  h4 {
    @include fonts.acumin-pro();
    font-weight: 700;
    font-size: 21px;
  }

  .subheader-main {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }

  h5 {
    margin-top: 0;
  }

  .subheader-main {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }

  p {
    font-weight: 400;
    font-size: 19px;
  }

  .stats-h1 {
    font-size: 64px;
  }

  .stats-p {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    max-width: 220px;
  }

  .byline-margin {
    margin: 10px 25px 10px 0;
  }

  .shareLink {
    margin-right: 20px;
  }

  .img-main {
    background-size: cover;
    background-position: center;
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  .img-main_vert {
    float: left;
    width: 33%;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      float: none;
      width: 100%;
      margin-right: 0;
    }
  }

  h6.blockQuote {
    width: 75%;
    margin: 50px auto;
  }

  .flex-col_mobile {
    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }
  }
}
