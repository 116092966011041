.card {
  padding: 3rem 0;
  border-bottom: 1px solid #d9d9d9;

  &:last-child {
    border-bottom: none;
  }
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.details {
  margin: 0.5rem 0;
}

.tags {
  display: flex;
  gap: 1rem;
}

.description {
  margin: 1.5rem 0;
}

.link {
  display: block;
  max-width: max-content;
}
