@use "../constants";
@use "../mixins";

.sec-trust-platform-section {
  padding: 25px 0 150px;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 1px 0 100px;
  }

  .platform-detail-cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      grid-template-columns: 1fr;
    }
  }

  .platform-detail-card {
    display: flex;
    gap: 1rem;
    padding: 2rem;
    border-radius: 8px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      box-shadow: 0px 4px 2px 0px rgb(0 0 0 / 15%);
    }

    p {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  .p-max-width {
    max-width: 575px;
  }

  .icon-trust {
    position: absolute;
    bottom: -50px;
    width: 100%;
    max-height: 343px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: -10px;
    }
  }

  .platform-detail-card-transition {
    display: flex;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      position: sticky;
    }
  }

  .platform-detail-card-transition:nth-child(1) {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: 150px;
      z-index: 4;
    }
  }

  .platform-detail-card-transition:nth-child(2) {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: 140px;
      z-index: 3;
    }
  }

  .platform-detail-card-transition:nth-child(3) {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: 130px;
      z-index: 2;
    }
  }

  .platform-detail-card-transition:nth-child(4) {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      bottom: 120px;
      z-index: 1;
    }
  }
}

.trust-platform-parallax-desktop.sec-parallax-section {
  @include mixins.respond-max(constants.$breakpoint-sm) {
    position: relative;
    z-index: revert;
  }
}

.trust-platform-parallax-mobile {
  @include mixins.respond-min(constants.$breakpoint-sm) {
    display: none;
  }
}
