@use "../mixins";
@use "../constants";
@use "../common/fonts/mixins" as fonts;

$section-padding: 100px;
$section-padding-mobile: 50px;
$flueidDecision-photo-breakpoint: 600px;
$checkmark-size: 1000px;
$gears-height: 975px;
$gears-top: 50px;
$gears-pt: 100px;
$gears-pb: 75px;

.gears-background {
  position: relative;
  background: linear-gradient(180deg, #ffffff, #f8f8f8);
}

.gears-img {
  width: 100%;
  height: $gears-height;
  position: sticky;
  top: $gears-top;
  width: 100%;
  padding-top: $gears-pt;
  padding-bottom: $gears-pb;
}

.solutionsSuite-section {
  position: relative;
  margin-top: calc(-1 * ($gears-height + $gears-top + $gears-pt + $gears-pb));

  .section-paddingTop {
    padding-top: $section-padding;
  }

  section {
    padding: $section-padding 0;

    @include mixins.respond-max(constants.$breakpoint) {
      padding: $section-padding-mobile 0;
    }
  }

  .section-content {
    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column-reverse;
    }
  }

  .flueidpro-section {
    display: flex;
    justify-content: space-around;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }
  }

  .flueidDecision-section {
    padding-top: 0;
  }

  .jumbo-checkmark {
    position: sticky;
    top: 0;
    @include mixins.imgCenterContain;
    background-image: url("../../images/home/graphic-checkmark-jumbo@2x.png");
    background-position-y: top;
    background-size: contain;
    width: 100%;
    max-width: $checkmark-size;
    height: 100vw;
    max-height: $checkmark-size;
  }

  .solutions-top-header-container {
    padding-top: 300px;

    @include mixins.respond-max(constants.$breakpoint) {
      padding-top: 150px;
    }
  }

  .solutions-header {
    max-width: 60%;
    margin: 0 auto;

    @include mixins.respond-max(constants.$breakpoint) {
      max-width: 100%;
    }
  }

  .solutions-row-flex {
    display: flex;
    justify-content: space-around;
    max-width: 900px;
    margin: 0 auto;
    flex-wrap: wrap;

    @include mixins.respond-max(constants.$breakpoint) {
      text-align: center;
    }

    p {
      padding: 0px 10px 0px 10px;
      font-weight: 500;
    }
  }

  .sulutions-subtitle-center {
    text-align: center;
  }

  .solutions-subtitle-maxwidth {
    max-width: 700px;
  }

  .txtContainer-left {
    margin-right: 10%;
    margin-left: 185px;

    @include mixins.respond-max(1300px) {
      margin-left: 100px;
    }

    @include mixins.respond-max(constants.$breakpoint) {
      margin: 0 auto;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
    }

    .txt-blue {
      margin-bottom: 0px;
      margin-top: 15px;
    }

    .txt-header-api {
      @include mixins.respond-range(400px, constants.$breakpoint) {
        margin-top: 30px;
      }
    }
  }

  .btn-maxwidth {
    max-width: fit-content;
  }

  .flueidDecision-paddingMobile {
    justify-content: space-between;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      padding-top: 60px;
    }

    @include mixins.respond-min(constants.$breakpoint-sm) {
      padding-top: 40px;
    }
  }

  .mt-md {
    margin-top: 50px;
  }

  .mb-xxsm {
    margin-bottom: 5px;
  }

  .mr-xsm {
    margin-right: 15px;
  }
}

.solutions-subsection {
  .p-maxWidth_large {
    max-width: 715px;
  }

  .photoContainer {
    position: relative;
    width: 35%;
    min-width: 500px;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 100%;
      min-width: 0;
    }
  }

  .flueidDecision-photoContainer_mobile {
    @include mixins.respond-max($flueidDecision-photo-breakpoint) {
      margin-right: 0;
      width: 102vw;
      left: -6%;
    }
  }

  .photo-flueidDecision {
    background-image: url("../../images/graphics/graphic-flueidAPI-min.png");
    @include mixins.imgCenterContain;
    width: 90%;
    height: 90%;
    min-height: 400px;
    background-size: contain;
    position: relative;

    @include mixins.respond-max(constants.$breakpoint) {
      min-height: 250px;
      width: 80%;
      margin: 0 auto;
    }

    @include mixins.respond-max(700px) {
      min-height: 200px;
    }
  }

  .svg-clearToClose {
    position: absolute;
    right: -125px;
    top: 35px;
    z-index: 1;

    @include mixins.respond-max($flueidDecision-photo-breakpoint) {
      right: 0;
      top: 190px;
      width: 300px;
    }
  }

  .svg-dots {
    position: absolute;
    width: 856px;
    height: 233px;
    bottom: -67px;
    right: -52px;

    @include mixins.respond-max($flueidDecision-photo-breakpoint) {
      display: none;
    }
  }

  .txtContainer {
    max-width: 444px;
    justify-content: center;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 0;

    .txt-extraLight {
      @include fonts.acumin-pro;
    }
  }

  h6 {
    margin-top: 0;
    max-width: 325px;
  }
}

.solutions-subsection.flueidPro-section {
  .section-content {
    background-color: transparent;
    position: relative;
  }

  .dashedLineGroup-1 {
    width: 80%;
    top: -60px;
    right: -5%;
  }

  .dashedLineGroup-2 {
    margin-top: 47px;
    width: 44%;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 80%;
    }
  }

  .dashedLineGroup-3 {
    bottom: 99px;

    @include mixins.respond-max(constants.$breakpoint) {
      bottom: -100px;
      display: none;
    }
  }

  .dashedLineMargin {
    margin-top: 75px;
  }

  .txtContainer {
    justify-content: flex-start;
    margin-top: 75px;
    min-width: 300px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-top: calc(#{$section-padding-mobile} * 2);
    }
  }

  .graphic-order-progress {
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    width: 680px;
    min-width: 680px;
    height: 900px;
    position: relative;
    right: -10%;
    top: -29px;

    @include mixins.respond-max(constants.$breakpoint) {
      width: 100%;
      min-width: 320px;
      max-width: 800px;
      height: 100vw;
      min-height: 575px;
      right: 0;
      top: 0;
      background-size: cover;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 104vw;
      right: 10%;
      min-height: 0;
    }
  }

  .btns-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }

  .btns-container__items-end {
    align-items: flex-end;
  }
}

.solutions-subsection.flueidBuilt-section {
  margin-top: -100px;

  @include mixins.respond-max(constants.$breakpoint) {
    margin-top: 0;
  }
  .txt-underline {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    @include mixins.textLink;
  }

  padding-top: 0px;

  .photoContainer {
    margin-right: 10%;
    min-width: 575px;

    @include mixins.respond-max(constants.$breakpoint) {
      min-width: 320px;
      left: 0;
    }
  }

  .txtContainer {
    max-width: 450px;
    @include mixins.respond-max(constants.$breakpoint) {
      margin: 0 auto;
    }

    h3 {
      font-size: 36px;
    }

    .txt-subheader {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 32px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
    }

    .arrow-learn-more {
      background-image: url("../../images/icons/arrow-right-blue.svg");
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      margin-top: 5px;
      margin-left: 15px;
    }

    .arrow-flex-container {
      display: flex;
    }
  }

  h6 {
    max-width: 355px;
  }

  .photo-flueidBuilt {
    background-image: url("../../images/home/graphic-flueid.built@2x.png");
    height: 500px;
    width: 600px;

    @include mixins.respond-max(650px) {
      width: 100%;
      height: 300px;
    }

    @include mixins.respond-max(constants.$breakpoint) {
      margin-top: 80px;
    }
  }

  .dashedLineGroup-1_mobile {
    display: none;
    bottom: 0;
    width: 75%;

    @include mixins.respond-max(constants.$breakpoint) {
      display: flex;
    }
  }
}
