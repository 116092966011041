@mixin imgCenterContain {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

%logo-partner_size {
  width: 150px;
  height: 65px;
}

%logo-flueid_size {
  width: 115px;
  height: 22px;
}

.logo-flueid {
  background-image: url("../../images/logos/Flueid_Registered\ Logo_K.png");
  @include imgCenterContain();
  @extend %logo-flueid_size;
}

.logo-flueid-white {
  background-image: url("../../images/logos/Flueid_Registered\ Logo_W.png");
  @include imgCenterContain();
  @extend %logo-flueid_size;
}

.logo-flueidmark {
  background-image: url("../../images/logos/logo-flueidmark.png");
  @include imgCenterContain();
  width: 50px;
  height: 50px;
}

.logo-flueid-badge {
  background-image: url("../../images/logos/badge-powerd.by.flueid.png");
  @include imgCenterContain();
  width: 100px;
  height: 50px;
}

.logo-app-store {
  background-image: url("../../images/logos/logo-app-store.png");
  @include imgCenterContain();
  width: 125px;
  height: 40px;
}

.logo-aquiline {
  background-image: url("../../images/logos/logo-aquiline@2x.png");
  @include imgCenterContain();
  width: 165px;
  height: 60px;
}

.logo-commerceVentures {
  background-image: url("../../images/logos/logo-commerce.ventures@2x.png");
  @include imgCenterContain();
  width: 165px;
  height: 60px;
}

.logo-detroit-ventures {
  background-image: url("../../images/logos/logo-detroit-ventures.png");
  @include imgCenterContain();
  width: 165px;
  height: 60px;
}

.logo-live-oak {
  background-image: url("../../images/logos/logo-live-oak.png");
  @include imgCenterContain();
  width: 165px;
  height: 60px;
}

.logo-blend {
  background-image: url("../../images/logos/logo-blend@2x.png");
  @include imgCenterContain();
  @extend %logo-partner_size;
}

.logo-bostonNational {
  background-image: url("../../images/logos/lgoo-boston.national.title-bw@2x.png");
  @include imgCenterContain();
  @extend %logo-partner_size;
}

.logo-jfq {
  background-image: url("../../images/logos/logo-jfq.lending@2x.png");
  @include imgCenterContain();
  @extend %logo-partner_size;
}
