@use "./constants" as c;
@use "./mixins.scss" as m;

.footer-container {
  padding: 50px 0;
  background-color: white;

  .content {
    width: c.$section-width;
    max-width: c.$section-maxWidth;
    display: flex;
    align-items: flex-end;
    margin: auto;

    @include m.respond-max(c.$breakpoint) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .content-text {
    width: 100%;
    margin-left: 20px;

    @include m.respond-max(c.$breakpoint) {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  a:hover {
    color: c.$nav-btn_gray;
  }

  .logo-flueidmark {
    cursor: pointer;
  }

  .links * {
    display: flex;
    margin-right: 20px;
  }

  .links {
    margin-top: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include m.respond-max(c.$breakpoint) {
      flex-direction: column;
      align-items: center;
    }

    div:last-child,
    a:last-child {
      margin-right: 0;
    }
  }

  .links-left {
    @include m.respond-max(c.$breakpoint) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .m-r-0 {
    margin-right: 0;
  }

  .m-r-5 {
    margin-right: 5px;
  }

  .m-l-5 {
    margin-left: 5px;
  }
  .privacy-terms,
  .links-right {
    @include m.respond-max(c.$breakpoint) {
      margin-right: 0;
      margin-top: 5px;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  .links-right {
    @include m.respond-max(c.$breakpoint) {
      width: 100%;
      max-width: 400px;
      * {
        margin: 5px;
      }
    }
  }
}
