.divider {
  height: 1px;
  border: none;
  background-color: black;
  margin: 1rem 0;
}

.vertical {
  flex-shrink: 0;
  border-width: 0px thin 0px 0px;
  border-style: solid;
  border-color: black;
  height: auto;
  align-self: stretch;
  margin: 0 1rem;
}

.flex_item {
  align-self: stretch;
  flex-shrink: 0;
}

.m_0 {
  margin: 0;
}

.mx_sm {
  margin: 0 1rem;
}

.my_sm {
  margin: 1rem 0;
}

.mx_md {
  margin: 0 2rem;
}

.my_md {
  margin: 2rem 0;
}

.mx_lg {
  margin: 0 4rem;
}

.my_lg {
  margin: 4rem 0;
}
