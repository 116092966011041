@use "./newsLetterSection.scss";
@use "./pressReleaseRow.scss";
@use "../mixins";
@use "../constants";
@use "./featuredStory.scss";
@use "./editorialCard.scss";
@use "./editorialRequestForm.scss";

$caseStudyCard-width: 310px;
$section-padding: 100px;

%col-left {
  width: 65%;
  max-width: 800px;
  margin-right: 6%;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    width: 100%;
    max-width: none;
    margin-right: 0;
  }
}

.mediaPage-container {
  background: linear-gradient(to bottom, black 2%, transparent 2%);

  section:not(.hero-section) {
    padding: $section-padding 0;
  }

  .heroSection-media {
    padding-bottom: 150px;
  }

  h1 {
    margin: 10px 0;
  }

  .pagination-margin {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }
  .flex-col-mobile {
    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column;
    }
  }

  .flex-col-mobile_sm {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      flex-direction: column;
    }
  }
}

.heroSection-media {
  a {
    color: white;
  }

  .col-1 {
    @extend %col-left;

    h5.txt-medium {
      max-width: 475px;
    }
  }

  .col-2 {
    max-width: 350px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      max-width: none;
      text-align: center;
    }
  }

  .txt-liz_maxWidth {
    max-width: 340px;
    text-align: left;
    margin: auto;
    @include mixins.respond-max(constants.$breakpoint-sm) {
      justify-content: center;
    }
    @include mixins.respond-max(450px) {
      display: flex;
      flex-direction: column;
    }
  }

  .title-bold-media {
    @include mixins.respond-max(450px) {
      font-weight: 800;
      margin: 0 auto;
    }
  }

  .subtitle-bold-media {
    @include mixins.respond-max(450px) {
      margin: 0 auto;
    }
  }
  .subheader-2_gray {
    color: #747474;
  }

  .subheader-2_divider {
    margin: 0 6px;
    @include mixins.respond-max(450px) {
      display: none;
    }
  }

  h4.txt-regular {
    font-size: 36px;
    text-align: left;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      text-align: center;
    }
  }

  .dashedLines-margin {
    margin: 100px 0;
    height: 2px;
  }

  .dashedLineGroup-1 {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      width: 75%;
    }
  }

  .img-liz {
    background-image: url("../../images/people/photo-liz@2x.jpg");
    background-size: cover;
    width: 175px;
    height: 200px;
    margin-bottom: 40px;
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .dots-margin {
    margin: 30px 0;
  }

  .icon-arrow_white {
    flex-shrink: 0;
    margin-left: 10px;
    position: relative;
    top: 12px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }
}

.featuredStory-section {
  background: #f4f4f4;

  @include mixins.respond-max(constants.$breakpoint) {
    padding-top: 0 !important;
  }

  h2 {
    margin-top: 0;
  }

  .img-featuredStory {
    @extend %col-left;
    object-fit: cover;
    flex-shrink: 0;
    height: 1008px;
    margin-top: -298px;
    margin-bottom: -$section-padding;
    //-----------------
    @include mixins.respond-max(constants.$breakpoint) {
      background-position: top;
      background-size: cover;
      margin: 0 0 constants.$margin-md;
      width: 101vw;
      max-width: none;
      height: 126vw;
      position: relative;
      right: 6%;
    }
  }

  .dots-margin {
    margin-bottom: 40px;
  }

  .inlineBlock {
    display: inline-block;
  }

  .icon-arrowRight {
    margin-left: 10px;
  }
}

.featuredAboutStory-section {
  background: #f4f4f4;
  padding-top: 50px;
  padding-bottom: $section-padding;

  @include mixins.respond-max(constants.$breakpoint) {
    padding-top: 0 !important;
  }

  h2 {
    margin-top: 0;
  }

  h3 {
    font-weight: bold;
  }

  .flex-col-mobile {
    @include mixins.respond-max(constants.$breakpoint) {
      display: block;
    }
  }

  .img-featuredStory {
    @extend %col-left;
    margin-top: 150px;
    flex-shrink: 0;
    height: 450px;
    margin-right: 50px !important;
    object-fit: contain;
    object-position: top;

    @include mixins.respond-max(constants.$breakpoint) {
      margin: 0;
      width: 101vw;
      max-width: none;
      height: 90vw;
      position: relative;
      right: 6%;
    }

    @include mixins.respond-range(constants.$breakpoint-sm, constants.$breakpoint) {
      margin-top: 100px;
    }
  }

  .dots-margin {
    margin-bottom: 40px;
  }

  .inlineBlock {
    display: inline-block;
  }

  .icon-arrowRight {
    margin-left: 10px;
  }

  .right-col-about {
    padding-top: 180px;

    @include mixins.respond-max(constants.$breakpoint) {
      padding-top: 0px;
    }
  }
}

.pressKit-section {
  h2 {
    margin-top: 0;
  }

  h6 {
    margin: 0;
  }

  .p-3 {
    margin: 15px 0;
  }

  .p-2:hover {
    color: constants.$nav-btn_gray;
  }

  .svg-icon_lg {
    min-width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }

  .download-width {
    width: fit-content;
  }

  .iconDownload {
    margin-left: 10px;
    position: relative;
    top: 2px;
  }

  .p-maxWidth {
    max-width: 355px;
  }

  .mediaKit-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      grid-template-columns: 1fr;
    }
  }

  .dashedLineGroup-1 {
    margin-top: -100px;
    width: 75%;
  }
}

.latestNews-section {
  h2:first-child {
    @include mixins.respond-max(constants.$breakpoint) {
      margin-top: 0;
    }
  }

  .minHeight-wrapper {
    min-height: 309px;

    @include mixins.respond-max(constants.$breakpoint-xsm) {
      min-height: 477px;
    }
  }

  .pagination-margin {
    margin-top: 50px;
  }

  .flex {
    align-items: center;
  }

  .col-3 {
    @include mixins.respond-max(constants.$breakpoint-xsm) {
      justify-content: flex-start;
    }
  }

  .p-2_marginBottom {
    margin-bottom: 30px;
  }

  .icon-svg_arrow {
    margin-left: 10px;
  }
}
