@use "../constants";
@use "../mixins";

.sec-flueid-different-section {
  padding: 150px 0 75px;
  background-color: white;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    padding: 104px 0 32px;
  }

  .main-text-content {
    margin-bottom: 75px;
    text-align: center;

    @include mixins.respond-max(constants.$breakpoint) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-bottom: 0;
    }
  }

  h2 {
    margin: 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  p {
    @include mixins.respond-max(constants.$breakpoint-sm) {
      margin-bottom: 30px;
    }
  }

  .p-max-width-mobile {
    @include mixins.respond-max(constants.$breakpoint) {
      max-width: 232px;
    }
  }

  .main-section-content {
    display: flex;
    gap: 4rem;
    position: relative;

    @include mixins.respond-max(constants.$breakpoint) {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }

  .quote-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 3rem 3rem 3rem 0;

    @include mixins.respond-max(constants.$breakpoint) {
      justify-content: flex-start;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      padding: 1rem 0;
    }
  }

  .quote-dots {
    background: radial-gradient(gray -1px, transparent 1px) 4px 6px/3px 3px;
    position: absolute;
    width: 200%;
    height: 100%;
    right: 0;
    top: 0;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .quote-card {
    background: white;
    padding: 3rem 3rem 3rem 8rem;
    position: relative;
    color: #5ab059;
    max-width: 1084px;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      padding: 0;
    }
  }

  .background-quotes {
    position: absolute;
    margin: 0;
    top: 100px;
    left: 34px;
    font-size: 300px;
    opacity: 0.2;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      font-size: 175px;
      top: 16px;
      left: -16px;
    }
  }

  .main-quote {
    line-height: 48px;
    font-weight: 600;
    margin: 0;
    max-width: 480px;

    @include mixins.respond-max(constants.$breakpoint-lg) {
      font-size: 23px;
      line-height: 32px;
      max-width: none;
    }
  }

  .security-guides {
    flex: 1;
    max-width: 480px;

    @include mixins.respond-max(constants.$breakpoint) {
      max-width: none;
    }
  }

  ul {
    padding-inline-start: 1.25rem;
  }

  li {
    margin-bottom: 1rem;
  }

  .dashedLineGroup-bottom {
    bottom: -75px;

    @include mixins.respond-max(constants.$breakpoint) {
      display: none;
    }
  }
}
