@use "../constants" as c;
@use "../mixins" as m;

.container-relative {
  position: relative;

  .dot-pattern {
    position: absolute;
    height: 50px;
    width: 100%;
    bottom: 0px;
    background-size: cover;
  }
}

.form-section {
  width: c.$section-width;
  max-width: c.$section-maxWidth;
  margin: auto;
  position: relative;
  padding-bottom: 200px;

  @include m.respond-max(c.$breakpoint) {
    padding: 50px 0;
  }

  .content-left {
    width: 100%;
  }

  .flueidProContactForm-inputSec {
    margin-top: 30px;

    .icon-arrow {
      position: absolute;
      bottom: 15px;
      right: 10px;
    }
  }

  .flueidProContactForm-submitBtnSec {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    align-items: center;

    @include m.respond-max(c.$breakpoint) {
      flex-direction: column;
      width: 100%;
      margin-top: 30px;
    }

    .p-4 {
      width: 60%;
      max-width: 325px;

      @include m.respond-max(c.$breakpoint) {
        width: 100%;
        max-width: 100%;
      }
    }

    .btn-black {
      @include m.respond-max(c.$breakpoint) {
        margin: 40px auto;
      }
    }
  }
}

.container-relative-home-equity {
  width: 90%;

  .dot-pattern {
    position: absolute;
    height: 50px;
    width: 80%;
    bottom: 0px;
    background-size: cover;
    max-width: 2100px;
  }
}
.form-section-home-equity {
  width: 100%;

  @include m.respond-max(c.$breakpoint) {
    padding: 50px 0 0;
  }

  .header-text-top {
    text-align: left;
    color: #777777;
    font-weight: 500;
  }
  .form-title {
    text-align: left;
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .form-subtitle {
    text-align: left;
    margin-top: 0px;
    font-size: 32px;
    line-height: 48px;
  }

  .flueidProContactForm-inputSec {
    margin-top: 30px;

    .icon-arrow {
      position: absolute;
      bottom: 15px;
      right: 10px;
    }
  }
  .text-input-half {
    width: 45%;
  }
  .text-input-half-margin {
    margin-right: 10%;
  }
  .flueidProContactForm-submitBtnSec {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    align-items: center;

    @include m.respond-max(c.$breakpoint) {
      flex-direction: column;
      width: 100%;
      margin-top: 30px;
    }

    .p-4 {
      width: 60%;
      max-width: 325px;

      @include m.respond-max(c.$breakpoint) {
        width: 100%;
        max-width: 100%;
      }
    }

    .btn-black {
      @include m.respond-max(c.$breakpoint) {
        margin: 40px auto;
      }
    }
  }
  .input-row-2 {
    display: flex;
  }
}
