@use "../constants";
@use "../mixins";

%section-width {
  margin: auto;
  width: constants.$section-width;
  max-width: constants.$section-maxWidth;
}

.hero-section {
  @include constants.heroSection-spacing;
  padding-bottom: 270px;

  .content {
    @extend %section-width;
  }
}

.hero-section.black {
  background-color: black;
  color: white;
}

.section-content {
  @extend %section-width;
}

.section-content.section-content.section-maxWidth-xxsm {
  max-width: constants.$section-maxWidth-xxsm;
}

.section-content.section-maxWidth-xsm {
  max-width: constants.$section-maxWidth-xsm;
}

.section-content.section-maxWidth-small {
  max-width: constants.$section-maxWidth-small;
}

.section-content.section-maxWidth-large {
  max-width: constants.$section-maxWidth-large;
}
