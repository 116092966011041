.blockQuote {
  font-style: italic;

  h2 {
    font-size: 40px;
    margin: 0;
    display: inline;
    position: absolute;
  }

  .openQuote,
  .closeQuote {
    top: -6px;
  }

  .openQuote {
    left: -35px;
  }

  .closeQuote {
    right: -25px;
  }

  span {
    position: relative;
  }
}
