@use "../mixins";
@use "../constants";

.loss-mit-container {
  .home-equity-hero-section {
    background-image: url("../../images/photos/heroimglossmit.png");
    width: 100%;
    height: 25vw;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: -100px 50px;

    @include mixins.respond-max(950px) {
      background-position: -200px 50px;
    }
    @include mixins.respond-max(800px) {
      background-image: url("../../images/photos/mobile-hero-loss-mit.png");
      background-position: 0px 50px;
      margin-bottom: 350px;
    }
    @include mixins.respond-min(2000px) {
      height: 20vw;
      max-height: 800px;
    }

    @include mixins.respond-max(1300px) {
      height: 30vw;
    }

    @include mixins.respond-max(800px) {
      height: 40vw;
    }

    @include mixins.respond-max(600px) {
      height: 50vw;
    }

    @include mixins.respond-max(500px) {
      height: 300px;
    }

    .mobile-text-container-loss-mit {
      height: 200px;

      position: absolute;
      bottom: -280px;
      @include mixins.respond-min(800px) {
        display: none;
      }
      @include mixins.respond-max(380px) {
        bottom: -240px;
      }

      .hero-title-mobile {
        font-size: 24px;
        font-family: "acumin-pro";
        display: flex;
        flex-direction: column;
      }
      .hero-bold-title-mobile {
        font-weight: 800;
      }
      .hero-subtitle-mobile {
        font-size: 32px;
        width: 100%;
        line-height: 40px;
        margin-top: 20px;
        font-family: "acumin-pro";
        font-weight: 300;
      }
    }

    .hero-content {
      margin-top: 150px;
      @include mixins.respond-max(1000px) {
        margin-top: 0px;
      }
      @include mixins.respond-max(600px) {
        display: none;
      }
    }
    .hero-title {
      font-size: 24px;
      color: white;
      font-family: "acumin-pro";
      display: flex;
      flex-direction: column;
      @include mixins.respond-max(600px) {
        font-size: 20px;
      }
      @include mixins.respond-max(800px) {
        display: none;
      }
    }
    .hero-bold-title {
      font-weight: 800;
    }
    .hero-subtitle {
      font-size: 46px;
      width: 50%;
      line-height: 60px;
      margin-top: 20px;
      color: white;
      font-family: "acumin-pro";
      font-weight: 300;
      @include mixins.respond-max(1000px) {
        font-size: 36px;
        line-height: 40px;
        width: 40%;
      }

      @include mixins.respond-max(800px) {
        display: none;
      }
    }
    .equity-navbar {
      display: flex;
      justify-content: space-between;
    }

    .flueid-logo {
      background-image: url("../../images/logos/logo-flueid.png");
      height: 40px;
      width: 100px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 5%;
    }

    .hero-right-container {
      position: absolute;
      bottom: -5px;
      right: 12%;
      background-color: rgba(0, 0, 0, 0.411);
      backdrop-filter: blur(5px);
      border: 1px solid white;
      padding: 30px 50px;
      z-index: 10;

      @include mixins.respond-max(900px) {
        padding: 20px 20px;
      }

      @include mixins.respond-max(800px) {
        right: 20%;
        left: 20%;
      }

      @include mixins.respond-max(800px) {
        bottom: -0px;
      }

      @include mixins.respond-max(600px) {
        right: 10%;
        left: 10%;
      }

      .text-white {
        color: white;
        font-size: 18px;
        font-family: "acumin-pro";
      }

      .hero-right-bold {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .border-top {
        border-top: 1px solid white;
        padding-top: 25px;
        margin-top: 10px;
      }

      .hero-right-light {
        margin: 15px 0px;
      }

      .hero-right-row {
        display: flex;
        @include mixins.respond-max(800px) {
          height: 50px;
        }
      }

      .hero-right-icon-green {
        background-image: url("../../images/icons/green-icon-alert.png");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 18px 20px 18px 0px;
      }

      .hero-right-icon-yellow {
        background-image: url("../../images/icons/icon-medium-alert-min.png");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 18px 20px 18px 0px;
      }

      .hero-right-icon-red {
        background-image: url("../../images/icons/icon-alert-min.png");
        height: 20px;
        width: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 18px 20px 18px 0px;
      }
    }
  }
}
