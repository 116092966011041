.title {
  font-size: 1.875rem;
  line-height: 1em;
  margin: 0;
}

.location {
  margin: 1rem 0;
}

.btns {
  display: flex;
  gap: 1rem;
}

.btn_apply {
  width: 230px;
}

.btn_copy {
  background-color: white;
  min-width: min-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  &:hover,
  &:focus {
    background-color: #f5f5f5;
  }
}

.section_title {
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 600;
}

.divider {
  background-color: #d9d9d9;
}

.tags {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}

.list {
  padding-inline-start: 1.25rem;
}
