@use "./mixins";
@use "../../constants";
@use "../../mixins" as mixins-media;

h1 {
  @include mixins.abril-fatface;
  @extend .txt-regular;
  font-size: 58px;
  line-height: 68px;
  margin: 1em 0;
}

h2 {
  @include mixins.abril-fatface;
  @extend .txt-regular;
  font-size: 48px;
  line-height: 54px;
}

h3 {
  @include mixins.acumin-pro;
  @extend .txt-regular;
  font-size: 32px;
  line-height: 35px;
}

h4 {
  @include mixins.abril-fatface;
  font-size: 20px;
  line-height: 27px;
}

h5 {
  @include mixins.acumin-pro;
  font-size: 22px;
  line-height: 36px;
}

h6 {
  @include mixins.acumin-pro;
  font-size: 20px;
  line-height: 29px;
}

.subheader-1 {
  @include mixins.acumin-pro;
  font-size: 26px;
  line-height: 35px;
}

.subheader-2 {
  @include mixins.acumin-pro;
  font-size: 16px;
  line-height: 20px;
}

.numbersGrid-header {
  @include mixins.abril-fatface;
  font-size: 64px;
  line-height: 87px;
  position: relative;

  @include mixins-media.respond-max(constants.$breakpoint-sm) {
    font-size: 40px;
  }
}

p {
  @include mixins.acumin-pro;
  @extend .txt-light;
  font-size: 20px;
  line-height: 36px;
}

.p {
  @include mixins.acumin-pro;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
}

.p-1 {
  @include mixins.acumin-pro;
  font-size: 18px;
  line-height: 26px;
}

.p-2 {
  @include mixins.acumin-pro;
  font-size: 15px;
  line-height: 18px;
}

.p-3 {
  @include mixins.acumin-pro;
  font-size: 14px;
  line-height: 20px;
}

.p-4 {
  @include mixins.acumin-pro;
  font-size: 12px;
  line-height: 16px;
}

.p-5 {
  @include mixins.acumin-pro;
  font-size: 10px;
  line-height: 21px;
}

.txt-extraLight {
  font-weight: 200;
}

.txt-light {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-medium {
  font-weight: 500;
}

.txt-semiBold {
  font-weight: 600;
}

.txt-bold {
  font-weight: 700;
}

.txt-blue {
  color: constants.$btn-blue;
}

.txt-gray {
  color: #555555;
}

.txt-lightGray {
  color: #95a5a6;
}

.txt-white {
  color: #ffffff;
}

.txt-underline {
  text-decoration: underline;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

a {
  @include mixins.acumin-pro;
  text-decoration: none;
  color: black;
}
