@mixin respond-min($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin respond-max($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin respond-range($min-width, $max-width) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin respond-range-height($min-height, $max-height) {
  @media screen and (min-height: $min-height) and (max-height: $max-height) {
    @content;
  }
}

@mixin respond-min-heigth($min-height) {
  @media screen and (min-height: $min-height) {
    @content;
  }
}

@mixin respond-max-height($max-height) {
  @media screen and (max-height: $max-height) {
    @content;
  }
}

@mixin respond-max-width-max-height($max-width, $max-height) {
  @media screen and (max-width: $max-width) and (max-height: $max-height) {
    @content;
  }
}

@mixin imgCenterContain {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@mixin imgCenterCover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin textLink {
  &:hover {
    text-decoration: underline;
    font-weight: 700;
  }
  &:focus {
    text-decoration: underline;
    font-weight: 700;
  }
}
