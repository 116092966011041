$inactive-color: #414141;
$active-color: white;

.rc-pagination.pagination-container {
  font-size: 16px;

  .rc-pagination-item {
    background: transparent;
    color: $inactive-color;
    border: 1px solid $inactive-color;
    border-radius: 50%;
    // NOT SHOWING PAGE NUMBER CONTROLS ANYMORE!!!!
    display: none;
  }

  .rc-pagination-item-active {
    font-weight: 500;
    color: $active-color;
    border-color: $active-color;
  }

  .rc-pagination-item a {
    color: $inactive-color;
  }

  .rc-pagination-item:focus,
  .rc-pagination-item:hover {
    border-color: $active-color;
    transition: all 0.3s;
  }

  .rc-pagination-item-active:focus,
  .rc-pagination-item-active:hover {
    border-color: $active-color;
  }

  .rc-pagination-item-active:focus a,
  .rc-pagination-item-active:hover a {
    color: $active-color;
  }

  .rc-pagination-item:focus a,
  .rc-pagination-item:hover a {
    color: $active-color;
  }

  .rc-pagination-item-active a {
    color: $active-color;
  }

  .rc-pagination-next,
  .rc-pagination-prev {
    outline: 0;
    margin: 0;
    min-width: 45px;
    height: 40px;
  }

  .rc-pagination-item-link {
    background-color: rgba(255, 255, 255, 0.15);
    color: #888888;
    border: none;
    font-size: 30px;
  }

  .rc-pagination-next:hover,
  .rc-pagination-prev:hover {
    .rc-pagination-item-link {
      border-color: none;
      color: white;
    }
  }

  .rc-pagination-jump-next button:after,
  .rc-pagination-jump-prev button:after {
    display: block;
    content: "•••";
    color: white;
  }

  .rc-pagination-options {
    display: none;
  }
}
