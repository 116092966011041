@use "../mixins";
@use "../constants";
@use "../common/fonts/mixins" as fontMixins;

.editorial-cards_section {
  padding: 100px 0;

  &.background_lightGray {
    background-color: #f4f4f4;
  }

  h2:first-child {
    margin-top: 0;
  }

  .editorial-cards_grid {
    display: grid;
    justify-content: center;
    grid-gap: 60px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 3fr));

    @include mixins.respond-max(constants.$breakpoint-lg) {
      grid-gap: 150px;
    }

    @include mixins.respond-max(constants.$breakpoint) {
      grid-gap: 60px;
    }

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: none;
    }
  }

  .editorial-cards_grid-padding {
    padding-bottom: 50px;
  }

  .editorial-cards_grid-item {
    justify-self: center;
  }

  .slick-slider {
    display: none;

    @include mixins.respond-max(constants.$breakpoint-sm) {
      display: block;
      margin: 100px 0;
    }
  }

  .editorial-slider_margin {
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
  }
}

.editorial-card {
  max-width: 100%;
  display: grid;
  grid-template-rows: 225px 1fr;
  height: 100%;
  border: 1px solid #e5e5e5;

  @include mixins.respond-max(constants.$breakpoint-sm) {
    max-width: 310px;
  }

  .editorial-card_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .editorial-card_content {
    background-color: #f7f7f7;
    padding: 24px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: -10px;
    position: relative;
  }

  .editorial-card_title {
    margin: 15px 0;
    line-height: 25px;
    @include fontMixins.truncateLine(3);
  }

  .editorial-card_title:first-child {
    margin-top: 0;
  }

  .editorial-card_subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    @include fontMixins.truncateLine(4);
  }
}
